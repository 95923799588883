import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OrderItem from "./OrderItem";
import emptyCart from "../../images/emptycart.png";
import Header from "../Header/header";
import { useNavigate } from "react-router-dom";
import "./history.css";

const History = () => {
  const { userLogged } = useSelector((state) => state.user);
  const { userData } = useSelector((state) => state.user);
  const { orders } = userData;
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/customer");
  };

  return (
    <div>
      <Header />
      {!userLogged ? (
        <>
          <h1 className="top1">Login to see your orders</h1>
          <div className="button-cont">
            <button className="button3" onClick={handleLoginClick}>
              Login
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="mt-24 md:mt-28">
            <h1 className="text-3xl font-extrabold mb-4 text-gray-800 text-center">
              MY ORDER HISTORY ({orders.length}{" "}
              {orders.length === 1 ? "Item" : "Items"})
            </h1>
            {orders.length !== 0 ? (
              <ul className="space-y-6 mb-20">
                {orders.map((eachOrder, index) => (
                  <OrderItem orderData={eachOrder} key={index} />
                ))}
              </ul>
            ) : (
              <div className="flex flex-col items-center justify-center mt-16">
                <img
                  src={emptyCart}
                  alt="empty cart"
                  className="w-auto h-auto mb-4"
                />
                <h1 className="text-xl font-semibold text-gray-700 mb-2">
                  You haven't placed an Order.
                </h1>
                <p className="text-gray-500 mb-4">Let's place some orders.</p>
                <Link to="/">
                  <button
                    type="button"
                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition"
                  >
                    GO TO SHOP
                  </button>
                </Link>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default History;
