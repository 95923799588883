import React from "react";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Fsign from "../SignUp/Fsign";

const EmailLogin = () => {
  const { userLogged } = useSelector((state) => state.user);

  const navigate = useNavigate();
  useEffect(() => {
    if (userLogged) {
      navigate("/cart");
    }
  }, [userLogged]);

  return (
    <div className="email-bg-Container">
      <Fsign />
    </div>
  );
};

export default EmailLogin;
