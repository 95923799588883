import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import DigistallLogo from "./../images/image 4digistall_logo.png";
import {
  FaFacebook,
  FaLinkedin,
  FaRegHandPointRight,
  FaTwitter,
} from "react-icons/fa";

function BlogDetail() {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [shopId, setShopId] = useState(null);
  const [shopData, setShopData] = useState(null);
  const url = window.location.href;

  const handleShare = (platform) => {
    let shareUrl = "";
    const encodedUrl = encodeURIComponent(url);

    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?url=${encodedUrl}`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}`;
        break;
      default:
        return;
    }

    window.open(shareUrl, "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    axios
      .get(`https://gotanbackend.digistall.in/api/blog/slug/${slug}`)
      .then((response) => {
        setBlog(response.data);
        if (response.data.shopId) {
          setShopId(response.data.shopId);
          axios
            .post(`https://backend.digistall.in/shop`, {
              shopId: response.data.shopId,
            })
            .then((response) => {
              setShopData(response.data.response);
              console.log(shopData, "shopdata");
            });
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching blog data:", error);
        setError("Error fetching blog data.");
        setLoading(false);
      });
  }, [slug]);

  if (loading) return <div className="text-center p-6">Loading...</div>;
  if (error) return <div className="text-center p-6 text-red-500">{error}</div>;

  console.log(shopData, "shopdata");
  return (
    <div className="flex-1 bg-[#f5f5f5]">
      <Helmet>
        <title>{blog.title} - Digistall</title>
        <meta name="description" content={blog.description} />
        <meta name="keywords" content={blog.keywords?.join(", ")} />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={blog.description} />
        <meta property="og:image" content={blog.coverImage} />
        <meta property="og:url" content={url} />
        <meta name="twitter:title" content={blog.title} />
        <meta name="twitter:description" content={blog.description} />
        <meta name="twitter:image" content={blog.coverImage} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className="md:w-9/12 w-11/12 m-auto px-4">
        <div className="flex flex-row gap-3 pt-4">
          <Link to={"https://digistall.in/"}>
            <img
              src={DigistallLogo}
              className="w-[40px] h-[40px]"
              alt="Digistall Logo"
            />
          </Link>
          <div className="py-2 merriweather-bold">Digistall </div>
        </div>
        {blog.shopId && (
          <>
            <div className="flex flex-row justify-between gap-3 pt-3">
              <div>
                Created by Digistall Seller
                <span className="text-blue-600  font-quicksand font-normal ml-6">
                  • {blog.shopId}
                </span>
              </div>
              <div className=" merriweather-bold text-blue-600">
                <a
                  href={`https://${blog.shopId}.digistall.in/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Check Out Our Shop <FaRegHandPointRight />
                </a>
              </div>
            </div>
            <div>
              Created At
              <span className="text-blue-600  font-quicksand font-normal ml-6">
                • {new Date(blog.createdAt).toLocaleDateString()}
              </span>
            </div>
          </>
        )}
        <div className="md:mt-10 mt-4">
          {blog.shopId ? (
            <>
              {" "}
              <div className="mb-6 flex flex-row gap-4">
                <img
                  src={blog.coverImage}
                  className="w-full h-auto  flex-shrink-0 md:w-1/2 object-cover rounded-md mb-6"
                  alt={blog.title}
                />
                <div>
                  {/* {shopData.products && shopData.products.length > 0 ? (
                    <div className="mb-6 border-2 border-gray-200 rounded-lg shadow-lg p-4 bg-white">
                      <h1 className="text-xl font-semibold text-center my-2 text-gray-800">
                        Check Our Product
                      </h1>
                      <img
                        src={shopData.products[0]?.productImages[0]}
                        className="w-full md:w-1/2 object-cover rounded-md mb-4"
                        alt={shopData.products[0]?.productName}
                      />
                      <h2 className="text-xl font-semibold mb-2">
                        {shopData.products[0]?.productName}
                      </h2>
                      <div className="text-gray-700 text-lg mb-2">
                        <span className="line-through text-gray-500">
                          ₹{shopData.products[0]?.productPrice}
                        </span>{" "}
                        <span className="text-red-500 font-bold">
                          ₹{shopData.products[0]?.productdiscountPrice}
                        </span>
                      </div>
                      <p className="text-gray-600 mb-4">
                        {shopData.products[0]?.productDescription}
                      </p>

                      <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full transition-colors duration-300">
                        Buy Now
                      </button>
                    </div>
                  ) : (
                    <p>No products available</p>
                  )} */}
                </div>
              </div>
              <div className="merriweather-regular">
                <h2 className="text-lg md:text-3xl font-bold mb-4">
                  {blog.title}
                </h2>
                <p className="text-base mb-4">{blog.description}</p>
                <div className="prose prose-lg max-w-none">
                  {blog.content &&
                    blog.content.map((paragraph, index) => (
                      <p key={index} className="mb-4">
                        {paragraph}
                      </p>
                    ))}
                </div>
              </div>
              <div className="bg-gray-200 rounded-md p-4 flex flex-col mt-4 mb-4">
                <div className="flex flex-col md:flex-row items-center justify-between">
                  <div className="text-xl font-semibold mb-2 md:mb-0">
                    Like what you see? Share with a friend.
                  </div>
                  <div className="flex items-center space-x-4 md:space-x-6">
                    <FaFacebook
                      className="text-2xl cursor-pointer"
                      onClick={() => handleShare("facebook")}
                    />
                    <FaTwitter
                      className="text-2xl cursor-pointer"
                      onClick={() => handleShare("twitter")}
                    />
                    <FaLinkedin
                      className="text-2xl cursor-pointer"
                      onClick={() => handleShare("linkedin")}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* Digistall Owner */}
              <div className="mb-6">
                <img
                  src={blog.coverImage}
                  className="w-full h-auto  object-cover rounded-md mb-6"
                  alt={blog.title}
                />
              </div>
              <div className="merriweather-regular">
                <h2 className="text-lg md:text-3xl font-bold mb-4">
                  {blog.title}
                </h2>
                <p className="text-base mb-4">{blog.description}</p>
                <div className="prose prose-lg max-w-none">
                  {blog.content &&
                    blog.content.map((paragraph, index) => (
                      <p key={index} className="mb-4">
                        {paragraph}
                      </p>
                    ))}
                </div>
              </div>
              <div className="bg-gray-200 rounded-md p-4 flex flex-col mt-4 mb-4">
                <div className="flex flex-col md:flex-row items-center justify-between">
                  <div className="text-xl font-semibold mb-2 md:mb-0">
                    Like what you see? Share with a friend.
                  </div>
                  <div className="flex items-center space-x-4 md:space-x-6">
                    <FaFacebook
                      className="text-2xl cursor-pointer"
                      onClick={() => handleShare("facebook")}
                    />
                    <FaTwitter
                      className="text-2xl cursor-pointer"
                      onClick={() => handleShare("twitter")}
                    />
                    <FaLinkedin
                      className="text-2xl cursor-pointer"
                      onClick={() => handleShare("linkedin")}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default BlogDetail;
