import {
  Navigation,
  Autoplay,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import image1 from "./images/1.jpeg";
import image2 from "./images/2.jpeg";
import image3 from "./images/3.jpeg";
import image4 from "./images/4.jpeg";
import image5 from "./images/5.jpeg";
import image6 from "./images/6.jpeg";
import image7 from "./images/7.jpeg";
import image8 from "./images/8.jpeg";
import image9 from "./images/9.jpeg";
import image10 from "./images/10.jpeg";
import image11 from "./images/11.jpeg";
import image12 from "./images/12.jpeg";
import image13 from "./images/13.jpeg";

const ParallaxImages = () => {
  return (
    <div className="w-full mt-4">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={30}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        loop
      >
        <SwiperSlide>
          <div className="flex justify-center mx-5">
            <div className="flex justify-center bg-slate-500 border-gray-400 border-[5px] rounded-lg shadow-xl w-[700px] h-[300px]">
              <img
                src={image1}
                alt="User Avatar"
                className="object-cover h-full w-full rounded-lg"
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-center mx-5">
            <div className="flex justify-center bg-slate-500 border-gray-400 border-[5px] rounded-lg shadow-xl w-[700px] h-[300px]">
              <img
                src={image2}
                alt="User Avatar"
                className="object-cover h-full w-full rounded-lg"
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-center mx-5">
            <div className="flex justify-center bg-slate-500 border-gray-400 border-[5px] rounded-lg shadow-xl w-[700px] h-[300px]">
              <img
                src={image3}
                alt="User Avatar"
                className="object-cover h-full w-full rounded-lg"
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-center mx-5">
            <div className="flex justify-center bg-slate-500 border-gray-400 border-[5px] rounded-lg shadow-xl w-[700px] h-[300px]">
              <img
                src={image4}
                alt="User Avatar"
                className="object-cover h-full w-full rounded-lg"
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-center mx-5">
            <div className="flex justify-center bg-slate-500 border-gray-400 border-[5px] rounded-lg shadow-xl w-[700px] h-[300px]">
              <img
                src={image5}
                alt="User Avatar"
                className="object-cover h-full w-full rounded-lg"
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-center mx-5">
            <div className="flex justify-center bg-slate-500 border-gray-400 border-[5px] rounded-lg shadow-xl w-[700px] h-[300px]">
              <img
                src={image6}
                alt="User Avatar"
                className="object-cover h-full w-full rounded-lg"
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-center mx-5">
            <div className="flex justify-center bg-slate-500 border-gray-400 border-[5px] rounded-lg shadow-xl w-[700px] h-[300px]">
              <img
                src={image7}
                alt="User Avatar"
                className="object-cover h-full w-full rounded-lg"
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-center mx-5">
            <div className="flex justify-center bg-slate-500 border-gray-400 border-[5px] rounded-lg shadow-xl w-[700px] h-[300px]">
              <img
                src={image8}
                alt="User Avatar"
                className="object-cover h-full w-full rounded-lg"
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-center mx-5">
            <div className="flex justify-center bg-slate-500 border-gray-400 border-[5px] rounded-lg shadow-xl w-[700px] h-[300px]">
              <img
                src={image9}
                alt="User Avatar"
                className="object-cover h-full w-full rounded-lg"
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-center mx-5">
            <div className="flex justify-center bg-slate-500 border-gray-400 border-[5px] rounded-lg shadow-xl w-[700px] h-[300px]">
              <img
                src={image10}
                alt="User Avatar"
                className="object-cover h-full w-full rounded-lg"
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-center mx-5">
            <div className="flex justify-center bg-slate-500 border-gray-400 border-[5px] rounded-lg shadow-xl w-[700px] h-[300px]">
              <img
                src={image11}
                alt="User Avatar"
                className="object-cover h-full w-full rounded-lg"
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-center mx-5">
            <div className="flex justify-center bg-slate-500 border-gray-400 border-[5px] rounded-lg shadow-xl w-[700px] h-[300px]">
              <img
                src={image12}
                alt="User Avatar"
                className="object-cover h-full w-full rounded-lg"
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-center mx-5">
            <div className="flex justify-center bg-slate-500 border-gray-400 border-[5px] rounded-lg shadow-xl w-[700px] h-[300px]">
              <img
                src={image13}
                alt="User Avatar"
                className="object-cover h-full w-full rounded-lg"
              />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default ParallaxImages;
