import React from "react";
import {
  Navigation,
  A11y,
  Autoplay,
  Pagination,
  Scrollbar,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFlip } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-flip";
import "./layout.css";

import be1 from "./images/be1.png";
import be2 from "./images/be2.png";
import be3 from "./images/be3.png";
import be4 from "./images/be4.png";

function BCrousal() {
  return (
    <div className="testimonial-carousel">
      <Swiper
        modules={[
          EffectFlip,
          Navigation,
          Pagination,
          Scrollbar,
          A11y,
          Autoplay,
        ]}
        spaceBetween={30}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        loop
      >
        <SwiperSlide>
          <div className="businessCrousal">
            <div className="img">
              <img src={be1} alt="User Avatar" height={20} width={20} />
            </div>
            <div className="name">Customization</div>
            <div className="point">
              As per your needs in order to reflect your goals
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="businessCrousal">
            <div className="img">
              <img src={be2} alt="User Avatar" height={20} width={20} />
            </div>
            <div className="name">Compliance</div>
            <div className="point">
              Follow the rules to keep your business safe and secure with us
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="businessCrousal">
            <div className="img">
              <img src={be3} alt="User Avatar" height={20} width={20} />
            </div>
            <div className="name">Marketing</div>
            <div className="point">
              Tell your story well to attract more customers to increase sales
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="businessCrousal">
            <div className="img">
              <img src={be3} alt="User Avatar" height={20} width={20} />
            </div>
            <div className="name">Operations</div>
            <div className="point">
              Keep things running smoothly and well for better growth of your
              business.
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default BCrousal;
