// // InstallButton.js

// // import React, { useEffect, useState } from "react";
// import { MdOutlineFileDownload } from "react-icons/md";

// const InstallButton = () => {
//   return (
//     <button className="buttonSignupok" id="pwaAppInstallBtn">
//       Download Application <MdOutlineFileDownload size={20} />
//     </button>
//   );
// };

// export default InstallButton;
// InstallButton.js

// import React, { useEffect, useState } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import file from "./Digistall.apk";
const InstallButton = () => {
  return (
    <>
      {/* <button className="buttonSignupok" id="pwaAppInstallBtn">
        Download Dekstop Application <MdOutlineFileDownload size={20} />
      </button> */}
      <a
        href={file}
        download
        className={`flex flex-row bg-[#0293a8] mr-2 rounded-md h-max px-2 py-2 mt-1 text-white`}
      >
        {/* <button className="buttonSignupok"> */}
        App <MdOutlineFileDownload size={20} />
        {/* </button> */}
      </a>
    </>
  );
};

export default InstallButton;
