import React from "react";
import {
  Navigation,
  A11y,
  Autoplay,
  Pagination,
  Scrollbar,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFlip } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-flip";

import main from "./images/main.jpeg";
function Crousal() {
  return (
    <div className="testimonial-carousel">
      <Swiper
        modules={[
          EffectFlip,
          Navigation,
          Pagination,
          Scrollbar,
          A11y,
          Autoplay,
        ]}
        spaceBetween={30}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        loop
        effect="flip"
      >
        <SwiperSlide>
          <div className="testimonial-item">
            <img src={main} alt="User Avatar" />
            <h4 className="testimonial-author">John Doe</h4>
            <p className="testimonial-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae
              ante velit. Suspendisse potenti. Proin id tincidunt justo.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="testimonial-item">
            <img src={main} alt="User Avatar" />
            <h4 className="testimonial-author">Jane Smith</h4>
            <p className="testimonial-text">
              Ut suscipit ex sit amet leo lobortis, a feugiat nulla condimentum.
              Fusce in mauris dui. Pellentesque habitant morbi tristique
              senectus et netus et malesuada fames ac turpis egestas.
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default Crousal;
