import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaSpinner } from "react-icons/fa";

function CustomerRiderection() {
  const navigate = useNavigate();
  const { userLogged } = useSelector((state) => state.user);

  const handleNavigation = () => {
    const subdomain = window.location.hostname.split(".")[0];
    const newUrl = `https://digistall.in/customerLogin?subdomain=${subdomain}`;
    // const newUrl = `http://localhost:3000/customerLogin?subdomain=${subdomain}`;

    window.location.href = newUrl;
  };

  useEffect(() => {
    if (userLogged) {
      navigate("/cart");
    } else {
      handleNavigation();
    }
  }, [navigate, userLogged]);

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="flex flex-col items-center">
        <FaSpinner color="#4A90E2" size={15} />
        <p className="mt-4 text-gray-600">Redirecting, please wait...</p>
      </div>
    </div>
  );
}

export default CustomerRiderection;
