import React from "react";

const EmitraTermsAndConditions = () => {
  return (
    <div className="privacy">
      <div className="privacy-heading"> Terms of Service for E-Mitra</div>
      <hr className="privacy-horizontal" />
      <div className="privacy-subheading-content">
        Hey! Kiosk Owner. We welcome you at Digistall- Your Gateway to
        E-commerce.
        <br />
        <br />
        You can build the website of your nearby business- Check Emitra Guide -
        Link
        <br />
        <br />
        General Rules of Building a Website :-
        <br />
        1. Setting up the domain by the permission of a seller - 1st step
        <br />
        2. Filling accurate details of seller’s name and Business Addresses and
        Mobile No.
        <br />
        3. Uploading 10 Products of Seller with price , stock count and
        description of each product up to 50 characters.
        <br />
        4. Uploading three pictures of team Members
        <br />
        5. Uploading the Welcome banner and shop logo.
        <br />
        6. Adding social media links, if any. 7. Sharing credentials of Store ID
        and password.
        <br />
        <br />
        Note :- All the photos and information should be provided by the seller.
        <br />
        <br />
        The Cost of this software is 3000 for 6 Months, where we are sharing the
        commission of 1200/ 6 Months to the Kiosk Owner.
        <br />
        <br />
        Kiosk owners can make multiple websites of businesses near their
        geographical location.
      </div>
    </div>
  );
};

export default EmitraTermsAndConditions;
