import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { verifyOTPNew } from "../fetchCalls/fetchRoutes";
import { useEffect, useState } from "react";
import { changeLoginStatus, setUserData } from "../fetures/user/userSlice";
import { FaSpinner } from "react-icons/fa";

const CustomerLogin2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userLogged } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);

  const email = Cookies.get("storeLoginData");

  useEffect(() => {
    if (userLogged) {
      navigate("/cart");
    } else {
      handleGoogleSignIn();
    }
  }, [dispatch]);

  const handleGoogleSignIn = async () => {
    setLoading(true);
    try {
      if (email) {
        const userData = { email: email };
        const { data } = await verifyOTPNew(userData);
        dispatch(setUserData(data));
        dispatch(changeLoginStatus());
        Cookies.remove("storeLoginData");
        Cookies.remove("storeLoginStatus");
        navigate("/cart");
      }
    } catch (error) {
      console.error("Error during Google Sign In:", error);
      // Handle error if needed
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      {loading ? (
        <FaSpinner className="animate-spin text-blue-500 text-5xl" />
      ) : (
        <div> loaded</div>
      )}
    </div>
  );
};

export default CustomerLogin2;
