import React from "react";
import {
  Navigation,
  A11y,
  Autoplay,
  Pagination,
  Scrollbar,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFlip } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-flip";
import "./layout.css";

import plug from "./images/plug.png";
import plug2 from "./images/plug2.png";
import plug3 from "./images/plug3.png";
import plug4 from "./images/plug4.png";
function LCrousal() {
  return (
    <div className="testimonial-carousel">
      <Swiper
        modules={[
          EffectFlip,
          Navigation,
          Pagination,
          Scrollbar,
          A11y,
          Autoplay,
        ]}
        spaceBetween={30}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        loop
      >
        <SwiperSlide>
          <div className="businessCrousal2">
            <div className="img">
              <img src={plug} alt="User Avatar" height={20} width={20} />
            </div>
            <div className="name">Plug in and Playout model</div>
            <div className="point">
              {" "}
              Easily integrate and start operating businesses with pre-built
              systems and resources
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="businessCrousal2">
            <div className="img">
              <img src={plug2} alt="User Avatar" height={20} width={20} />
            </div>
            <div className="name">Subscription Fees</div>
            <div className="point">
              {" "}
              Monthly fee grants access to tools for business listing and
              operation directly from the owners to you
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="businessCrousal2">
            <div className="img">
              <img src={plug3} alt="User Avatar" height={20} width={20} />
            </div>
            <div className="name">Premium services</div>
            <div className="point">
              All the services at your fingertips at ease and also saving at
              multiple costs
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="businessCrousal2">
            <div className="img">
              <img src={plug4} alt="User Avatar" height={20} width={20} />
            </div>
            <div className="name">Rider and Parcel Commission</div>
            <div className="point">
              As per your needs in order to reflect your goals
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default LCrousal;
