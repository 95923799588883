import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Fsign from "./Fsign";

const EmailSignup = () => {
  const { userLogged } = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (userLogged) {
      navigate("/");
    }
  });

  return (
    <div className="email-bg-Container">
      <Fsign />
    </div>
  );
};

export default EmailSignup;
