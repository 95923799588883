import React from "react";
import EmitraGuide from "./train.docx";
import "./LandingPage.scss";

function TrainingDownload() {
  const openPdfInNewTab = () => {
    window.open(EmitraGuide, "_blank");
  };

  return (
    <div>
      <button onClick={openPdfInNewTab}>Training</button>
    </div>
  );
}

export default TrainingDownload;
