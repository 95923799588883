// import { configureStore } from "@reduxjs/toolkit";
// import storeReducer from './fetures/store/storeSlice';
// import cartReducer from './fetures/cart/cartSlice';
// import userReducer from './fetures/user/userSlice';
// import riderReducer from "./fetures/rider/riderSlice";

// export const store = configureStore({
//                      reducer: {
//                             store: storeReducer,
//                             cart: cartReducer,
//                             user: userReducer,
//                            rider: riderReducer
//                   }
//                })

// const saveState = (state) => {
//     try{
//         const serialisedStore = JSON.stringify(state.store);
//         const serialisedCart = JSON.stringify(state.cart);
//         const serialisedUser = JSON.stringify(state.user);
//           const serialisedRider = JSON.stringify(state.rider);

//         window.localStorage.setItem('hri_local_store', serialisedStore)
//         window.localStorage.setItem('hri_local_cart', serialisedCart)
//         window.localStorage.setItem('hri_local_user', serialisedUser)
//           window.localStorage.setItem("hri_local_user", serialisedRider);

//     }catch(error){
//         saveState(store.getState())
//     }
// }

// store.subscribe(() => {
//     saveState(store.getState())
// })

import { configureStore } from "@reduxjs/toolkit";
import storeReducer from "./fetures/store/storeSlice";
import cartReducer from "./fetures/cart/cartSlice";
import userReducer from "./fetures/user/userSlice";
import adminSlice from "./fetures/admin/adminSlice";

export const store = configureStore({
  reducer: {
    store: storeReducer,
    cart: cartReducer,
    user: userReducer,
    admin: adminSlice,
  },
});

const saveState = (state) => {
  try {
    const serialisedStore = JSON.stringify(state.store);
    const serialisedCart = JSON.stringify(state.cart);
    const serialisedUser = JSON.stringify(state.user);
    const serialisedRider = JSON.stringify(state.rider);
    const serialisedAdmin = JSON.stringify(state.admin);

    window.localStorage.setItem("hri_local_store", serialisedStore);
    window.localStorage.setItem("hri_local_cart", serialisedCart);
    window.localStorage.setItem("hri_local_user", serialisedUser);
    window.localStorage.setItem("hri_local_rider", serialisedRider);
    window.localStorage.setItem("hri_local_admin", serialisedAdmin);
  } catch (error) {
    saveState(store.getState());
  }
};

store.subscribe(() => {
  saveState(store.getState());
});
