import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  // apiKey: "AIzaSyBWgMhmBdUbB1as8AZ9IGFkUxXLrJegvZk",
  // authDomain: "digistall.in",
  // projectId: "fir-c7a5c",
  // storageBucket: "fir-c7a5c.appspot.com",
  // messagingSenderId: "6284709583",
  // appId: "1:6284709583:web:a24a21d2fdd3383a54f1ba",
  // measurementId: "G-QK5PF89ZPP",
  apiKey: "AIzaSyC16EtzjYF0oKMkrH8IZ6zx4ZiED-scozY",
  authDomain: "humans-of-rural-india-d2b22.firebaseapp.com",
  projectId: "humans-of-rural-india-d2b22",
  storageBucket: "humans-of-rural-india-d2b22.appspot.com",
  messagingSenderId: "43638107391",
  appId: "1:43638107391:web:aaf9916a04958ef90bf0ca",
  measurementId: "G-SKBC8H5EH4",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
