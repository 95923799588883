import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="privacy">
      <div className="privacy-heading"> Terms of Service for digistall.in</div>
      <hr className="privacy-horizontal" />
      <div className="privacy-subheading-content">
        These terms and conditions ("Terms") and the privacy policy ("Privacy
        Policy") (available at https://digistall.in/) collectively refer to the
        "User Agreement" and constitute an electronic record under the
        Information Technology Act of 2000 ("IT Act") and any rules made
        thereunder, as well as the provisions of other statutes governing
        electronic records that have been amended from time to time.
        <br />
        This User Agreement is published in accordance with Rule 3(1) of the
        Information Technology (Intermediaries Guidelines) Rules, 2011, which
        mandates the publication of the terms and conditions for accessing or
        using the website digistall.in (including the uniform resource locator
        created for each Merchant) ("Website"), the mobile application available
        on the Play Store and app store—Digistall i.e. ("App"), and the uniform
        resource locator created for each Merchant. operated by Siyag rural
        market private limited, a company incorporated under the laws of India
        and its registered office at C/O Daya Ram Siyag, Nema Ram Siyag, near
        the secondary high school, Gotan
      </div>
      <div className="privacy-subheading">1. General:</div>
      <div className="privacy-subheading-content">
        1.1 Any natural or legal person (including any legal heirs,
        administrators, or successors) who has chosen to become a user of the
        Platform by accessing or using the Platform is referred to as a "User"
        for these Terms and "You" (or any variant thereof) whenever the
        situation may require. You represent and warrant that You are authorised
        to accept these Terms on behalf of any corporation, group of people,
        governmental body, or other legal entity and to use the Services (as
        described below) or the Platform.
        <br />
        1.2 Digistall.in, "We", "us", "our," or "Company" refer to Siyag rural
        market pvt. limited or any of its licensees, whether present or future,
        unless the context dictates otherwise.
        <br />
        1.3 Through its platform, the company facilitates transactions between
        buyers and sellers of (a) consumer goods, (b) groceries, art crafts,
        consumables, etc., and (c) any other goods or services (collectively,
        "Platform Services"). The purchasers (referred to as "Buyers") can
        select and place orders (referred to as "Orders") from a variety of
        goods and services that are posted and made available for purchase by
        different merchants, including restaurants, eateries, grocery stores,
        and other service providers (referred to as "Merchants"), on the
        Platform.
        <br />
        1.4 Before using or enrolling on the Platform, listing any items,
        accessing any materials, information, or services, or publishing any
        information at or through the Platform, please read these Terms
        carefully.
        <br />
        1.5 Your 'approval' of the User Agreement will make it enforceable
        against you as a User. The term "acceptance" refers to Your agreement to
        provide the Platform with the information requested on the sign-up page
        or by merely accessing or browsing the Platform. Please do not provide
        the needed information on the signup page and click the "Accept" button,
        or attempt to access, view, visit, download, or otherwise use the
        Platform (or any of its components) if you do not agree with or are not
        willing to be bound by the User Agreement and our Policies (described
        below).
      </div>
      <div className="privacy-subheading">2. Amendment</div>
      <div className="privacy-subheading-content">
        2.1 By using our Services, you affirm that you are at least 18 years old
        and have the legal capacity to enter into these Terms. You represent and
        warrant that you can bind any organisation or entity to these Terms if
        you are using our Services on their behalf.
        <br />
        2.2 The Company retains the right to revoke access at any time,
        including the termination of membership and deletion of the account, to
        anyone who the Company believes has violated the terms of the User
        Agreement. The Company also reserves the right to modify the Platform
        and/or change these Terms and/or Policies at any time.
        <br />
        2.3 You should be aware that any changes to the User Agreement
        incorporated herein by reference will only be made available on the
        Platform via links that are open to the public. By accessing, browsing,
        or using the Platform, you agree that this publication will serve as
        adequate notice for all purposes against You. The updated version/terms
        will become operative as soon as the Company posts them on the Platform.
        <br />
        2.4 the Company shall not be liable to give any prior notice to the
        Users for any amendments to the User Agreement, which in the opinion of
        the Company, has the effect of diminishing the User’s existing rights or
        imposing additional obligations. You understand and hereby accept that
        the decision, whether any proposed amendments to the User Agreement have
        the effect of diminishing the User’s existing rights or imposing
        additional obligations on the User, shall be decided by the Company in
        its sole discretion
      </div>
      <div className="privacy-subheading">3. Eligibility:</div>
      <div className="privacy-subheading-content">
        3.1 You must be 18 (eighteen) years of age or older to use the Platform.
        Only those people who can enter into and carry out contracts that are
        legally binding under the Indian Contract Act of 1872 may use or access
        the platform.
        <br />
        3.2 The User and/or his natural or assigned guardian alone shall be
        accountable for the consequences in accordance with the applicable Law
        of the country to which the User belongs. The Company shall not be
        liable in the event that the User provides any incorrect information,
        including the User's age.
        <br />
        3.3 If you are a minor using the Platform, the Company disclaims all
        liability for any third-party claims resulting from such unauthorised
        use of the Platform.
      </div>
      <div className="privacy-subheading">
        4. Profile Registration and Creation
      </div>
      <div className="privacy-subheading-content">
        4.1 In order to be eligible to access the relevant Services, a User must
        first register and create a profile by generating a username and
        password or registering using mobile verification and providing basic
        information about the User.
        <br />
        4.2 Your username, password, and user code (if any) are secret
        information that you are completely responsible for keeping private.
        <br />
        4.3 The User hereby acknowledges and accepts that the Platform will
        allow access to anybody who has gained Your username, password, or any
        code in the same way that it would have granted access to You. You are
        also agreeing to be accountable for all actions taken using Your
        username, password, or any code. The User is in charge of protecting the
        privacy of their account access.
        <br />
        4.4 Any real or suspected unauthorised use of the User's account or
        password must be reported right away to the Company. While the Company,
        its officers, shareholders, affiliates, workers, partners, contractors,
        or agents (collectively, "Personnel") shall not in any way be liable for
        any losses resulting from any breach of confidentiality of Your
        username, password, or user code or any unauthorised use of Your
        account, You shall be responsible for any losses incurred by the Company
        or such other parties as the case may be as a result of any unauthorised
        use of Your account.
        <br />
        4.5 You acknowledge that you would only register on the Platform or use
        it to give yourself the technical means to conduct your legal business
        online. You agree that you will not use the Platform in any way other
        than for the aforementioned purposes and for purposes that are
        prohibited by the applicable Law.
      </div>
      <div className="privacy-subheading">5. User Information</div>
      <div className="privacy-subheading-content">
        5.1 When registering on the Platform or when prompted or requested to do
        so for any other reason, you undertake to submit true, accurate,
        current, and complete information.
        <br />
        5.2 Aspects of Your private life and general information about You may
        be revealed or made public by some details you include in Your profile
        on the Platform. By using the Platform, You fully and freely agree to be
        bound by the provisions of the User Agreement, and You are solely
        responsible for disclosing any information that may be deemed "personal"
        or "sensitive" by applicable Laws.
        <br />
        5.3 You will need to use specific hardware, programmes, and data
        connections in order to use our Services; we do not often supply these
        things. You agree to download and install updates to our services,
        including automatically downloading and installing such updates, for as
        long as you use them.
        <br />
        5.4 All carrier data plan fees, additional costs, and taxes related to
        your usage of our services are your responsibility. For our services,
        which may include relevant taxes, we may charge You. Unless otherwise
        required by law, we do not offer returns for our services.
        <br />
        5.5 The validity of any sensitive personal data or personal information
        provided by the User to the Company or to any other person working on
        the Company's behalf shall not be subject to any liability on the part
        of the Company.
        <br />
        5.6 It is against the law for you to use a false identity, and you also
        promise not to log in or register using the name of another person. To
        make sure that the information you offer on the Platform is always true,
        accurate, current, complete, and not misleading, it is your
        responsibility to maintain and promptly update the information supplied
        while signing up, verifying, or for any other purpose.
        <br />
        5.7 The Company shall not be liable for the accuracy of any sensitive
        personal data or personal information submitted by the User to the
        Company or to any other person acting on the Company's behalf.
        <br />
        5.8 You also agree to refrain from logging in or registering under the
        name of another person because doing so is illegal. You are responsible
        for maintaining and immediately updating the information provided when
        joining up, verifying, or for any other purpose in order to guarantee
        that the information you provide on the Platform is always true,
        accurate, current, complete, and not misleading.
        <br />
        5.9 Once you've created an account on the Platform, you agree to give
        the Platform access to your mobile device so it can track your location,
        the mobile phone numbers of other Platform users, any internal or
        external storage on the device, internet access, vibration control,
        accounts on the device, and any other data or information that has been
        downloaded, added, edited, stored, used, or deleted from the device.
      </div>
      <div className="privacy-subheading">6. Electronic Communication</div>
      <div className="privacy-subheading-content">
        6.1 You consent to keep yourself informed of all data, information, and
        communications relating to You that the Company makes available on the
        Platform. Additionally, you consent to receive communication from the
        Company via electronic documents, including emails and/or SMS, which
        will be deemed sufficient for the service of notice/electronic records.
        This includes your use of the Platform and the provision of any data or
        information, including correspondence (by email or otherwise) to or by
        the Company.
        <br />
        6.2 You are aware that any fees for such access (including text
        messaging fees for messages sent from your mobile device) may fall on
        your shoulders. Users may receive information from our messages to them
        regarding different aspects of our services
        <br />
        6.3 Unless you have chosen not to receive it, the Company may also send
        you promotional materials. We might have to send You some emails or
        notifications, like administrative messages and service announcements.
        It's possible that You cannot opt out of getting these communications
        since they are regarded as being a part of the Services and Your
        account. You must update your account information if you added your
        phone number and then changed it or deactivated it in order to stop us
        from contacting anyone using your old number.
      </div>
      <div className="privacy-subheading">7. General Terms</div>
      <div className="privacy-subheading-content">
        7.1 Regarding the goods and services that the merchants are offering,
        only the buyer and the merchant are offering and agreeing to any
        commercial or contractual terms. The price, applicable taxes, shipping
        fees, payment terms, date, period, and mode of delivery, warranties
        connected to products and services, and after-sale services associated
        with products and services are just a few examples of
        commercial/contractual terms. The offering or acceptance of such
        commercial/contractual conditions between the Buyers and Merchants is
        not within the Company's direction, control, or influence in any way.
        However, in accordance with the agreement between the Company and
        Merchants, the Company may provide support services to Merchants in
        relation to Order fulfilment, logistics, payment method, payment
        collection, and other ancillary services.
        <br />
        7.2 Regarding the item-specifics (such as legal title, creditworthiness,
        identity, etc.) of any of the Merchants, the Company makes no
        representations or warranties. You are urged to exercise your best
        judgment in this matter and independently confirm the legitimacy of any
        specific Merchant you decide to do business with on the Platform. All
        promotions from merchants and third parties are subject to the terms and
        conditions set forth by such parties, and the company disclaims all
        liability in connection with such promotions.
        <br />
        7.3 The Company neither expressly nor implicitly supports or endorses
        the sale or purchase of any products or services on the Platform, nor
        does it make any representations or warranties regarding the specifics
        (such as quality, value, saleability, etc.) of the products or services
        proposed to be sold, offered to be sold, or purchased on the Platform.
        The Company disclaims all responsibility for any mistakes or omissions,
        whether they were made on their own behalf or those of other parties
        (such as the Merchants).
        <br />
        7.4 Any non-performance or breach of a contract between Buyers and
        Merchants is not the Company's fault. The Merchant consents to hold the
        Company harmless from any and all losses incurred by the Company as a
        result of the Merchant's usage of the Platform and communications with
        customers therein.
        <br />
        7.5 The Company runs an online marketplace and acts as a facilitator.
        Throughout any transaction between a buyer and a seller on the platform,
        the Company does not acquire or take ownership of any of the goods or
        services provided by the seller. The Company will never have a right,
        title, or interest in the products, and it will never be held
        responsible for any agreements made between the Buyer and the Merchant.
        <br />
        7.6 The Company's sole role is to inform the Merchant of any complaints
        from the Buyer regarding effectiveness, quality, or any other similar
        issues. The Company shall also direct the Buyer to the Merchant's
        customer call centre. Concerns from the Buyer must only be addressed by
        the Merchant. If you have a complaint about a Merchant you can access
        through our Platform, we will do everything in our power to help you by
        giving you the information you need to resolve the issue, including
        information about the Merchant and the particular Order the complaint
        relates to.
      </div>
      <div className="privacy-subheading">8. Terms for Merchants</div>
      <div className="privacy-subheading-content">
        8.1 The merchants certify that they have the right to sell the items
        offered for sale on the platform. Only written descriptions, graphics,
        and images describing your for-sale goods may be included in listings.
        Every product that is listed must be listed on the Platform in the
        proper category. It is completely forbidden and would be deemed a
        violation of the User Agreement to approach the Buyers about doing
        business offline or outside of the Platform. For orders to be filled
        successfully, all stated products must be kept in stock.
        <br />
        8.2 The Merchants may list and provide the details of the products/
        services offered for sale to the Buyers as registered users of the
        Platform, however, the Merchants shall not be permitted to sell any
        product that falls under the list of banned items (a document must be
        created for this) identified by the Company from time to time, or is
        otherwise prohibited for sale under applicable Law.
        <br />
        8.3 The Merchants hereby represent and warrant that the product/service
        descriptions, photos, and other content are true to its appearance,
        nature, quality, intended use, and other characteristics, and are
        complete and accurate.
        <br />
        8.4 The product listing descriptions must accurately reflect the
        products' true state and must not be deceptive. You undertake to return
        any money you may have received from the Buyer if the product
        description does not match the actual product.
        <br />
        8.5 You must adhere to the User Agreement's rules and refrain from
        abusing or misusing the Platform. Anytime this occurs, the Company has
        the right to suspend your account or prevent you from ever again having
        access to the Platform.
      </div>
      <div className="privacy-subheading">
        9. Terms for Delivery using Digistall
      </div>
      <div className="privacy-subheading-content">
        9.1 The sellers agree to provide a proper, accurate, fair, correct, and
        factual statement about the nature and worth of the products.
        <br />
        9.2 Sellers may be responsible for additional fees if the product's
        reported description, weight, or price is inaccurate.
        <br />
        9.3 The registered vendors must ensure that the goods they are selling
        are original and fit the website's description. The vendors acknowledge
        that the commodities being offered are not "prohibited goods" as defined
        in Annexure I ("Prohibited Goods") and that they are within the legal
        parameters for transportation.
        <br />
        9.4 Any dispute involving an order that was lost in transit, returned to
        sender (RTO), not delivered, or related to an incorrect status update
        must be raised within 45 days of the creation date of the shipment.
      </div>
      <div className="privacy-subheading">10. User's Obligation</div>
      <div className="privacy-subheading-content">
        10.1 You agree, undertake and confirm that Your use of Platform shall be
        strictly governed by the following binding principles
        <br />
        10.2 You shall not host, display, upload, download, modify, publish,
        transmit, update or share any information which: Belongs to another
        person and which
        <br />
        1. Belongs to another person and which You do not have any right to; is
        grossly harmful, harassing, blasphemous, defamatory, obscene,
        pornographic, paedophilic, libellous, slanderous, criminally inciting or
        invasive of another's privacy, hateful, or racially, ethnically
        objectionable, disparaging, relating or encouraging money laundering or
        gambling, or otherwise unlawful in any manner whatsoever; or unlawfully
        threatening or unlawfully harassing including but not limited to
        "indecent representation of women" within the meaning of the Indecent
        Representation of Women (Prohibition) Act, 1986; is in any way deceptive
        or inaccurate;
        <br />
        1. is blatantly offensive to the online community, such as content that
        is sexually explicit or that encourages obscenity, paedophilia, racism,
        bigotry, hatred, or physical damage of any type against any group or
        person; harasses or supports harassing another person;
        <br />
        2. promotes unlawful behaviour or conduct that is abusive, threatening,
        obscene, defamatory, or libellous; infringes upon or violates any third
        party's rights, including but not limited to intellectual property
        rights, privacy rights (including without limitation unauthorised
        disclosure of a person's name, email address, physical address, or phone
        number), or rights of public access to information.
        <br />
        3. includes password-protected or restricted access pages, hidden pages,
        or pictures (those not connected to or from another page that is
        accessible);
        <br />
        4. provides content that inappropriately uses people's personal
        information or exploits them sexually, violently, or in other ways;
        divulges instructions on how to make or purchase illegal weapons, invade
        the privacy of others, or produce computer viruses;
        <br />
        5. contains photos, video, or recordings of another person (in
        association with a child or an adult);
        <br />
        6. tries to gain unauthorised access to the Platform or to profiles,
        blogs, communities, account information, bulletins, friend requests, or
        other areas of the Platform, or exceeds the scope of authorised access,
        or requests passwords or other personally identifying information from
        other Users for commercial or illegal purposes;
        <br />
        7. engages in commercial endeavours and/or sales without first obtaining
        our express consent, including but not limited to giveaways, barter,
        advertising, pyramid schemes, and the purchase or sale of
        Platform-related goods. In these Terms, "the Company's prior written
        consent" refers to any message from the Company's authorised
        representative that is provided in response to Your request,
        specifically addresses and approves the activity or conduct for which
        You are asking for permission, and does so in writing;
        <br />
        8. interferes with another user's use and enjoyment of the Platform or
        any other third party's use and enjoyment of comparable services; makes
        reference to any website or URL that, in our sole discretion, contains
        content that is inappropriate for the Platform or engages in any
        gambling activity that is or could be interpreted as being illegal.
        <br />
        9. contains computer programming routines such as trojan horses, worms,
        time bombs, cancelbots, or easter eggs that may harm systems, data, or
        personal information, or that threaten the unity, integrity, defence,
        security, or sovereignty of the state; contains software viruses or any
        other computer code, files, or programmes designed to interrupt,
        destroy, or limit the functionality of any computer resource.
        <br />
        10. whether directly or indirectly, offers, attempts to offer, trades,
        or tries to trade in any object, the dealing of which is false,
        inaccurate, or misleading
      </div>
      <div className="privacy-subheading">11. Action</div>
      <div className="privacy-subheading-content">
        11.1 In the event that You violate the User Agreement, the Company has
        the right to immediately revoke Your access to and use of the Platform
        without prior notice. Any unlawful content you submit or display on the
        Platform may also be immediately and completely removed, and/or it may
        be reported to law enforcement authorities for possible legal action.
        <br />
        11.2 You agree and acknowledge that the Company or the Platform shall
        not in any way be responsible or liable for removing or deleting such
        information if the Company terminates Your access to the Platform. The
        Company may, in its sole and absolute discretion, remove and destroy any
        data and files stored by You on its servers.
      </div>
      <div className="privacy-subheading">12. Endorsements</div>
      <div className="privacy-subheading-content">
        12.1 We do not recommend any Merchant or the goods or services they
        provide. In addition, even though these Terms demand that You furnish
        truthful Information, we make no effort to verify or substantiate its
        claimed identity. We disclaim all liability for any harm or loss brought
        on by your dealings with Merchants.
        <br />
        12.2 By using the Services, You agree that any legal recourse or
        liability You seek for the acts or omissions of Merchants or other third
        parties shall be limited to a claim against the 11.3 specific Merchant
        or another third party who injured You and that You shall not attempt to
        hold us liable for or seek any legal recourse from, such acts or
        omissions.
      </div>
      <div className="privacy-subheading">13. Disclaimer</div>
      <div className="privacy-subheading-content">
        13.1 Some aspects and capabilities of the Platform may not be fully
        functional as it is always being upgraded.
        <br />
        13.2 There might be delays, omissions, or inaccuracies in the content
        provided on the platform, as well as delays or errors in how the
        platform functions, as a result of the unforeseen events that can happen
        in the electronic distribution of information and the limitations
        inherent in providing information gathered from multiple sources. We do
        not guarantee that the information supplied is accurate in all
        circumstances as a consequence.
        <br /> 13.3 We clearly disclaim any and all obligations that may result
        from any fraudulent use of credit or debit cards.
        <br /> 13.4 The Company disclaims all responsibility for any legal
        obligations that may be broken, including those pertaining to goods and
        services.
        <br /> 13.5 The information in the materials is being made available
        with the understanding that we make no guarantees, representations, or
        warranties of any kind, whether expressed or implied, with regard to the
        professionals' qualifications, expertise, quality of work, or other
        information herein, even though the materials provided on the Platform
        were prepared to provide accurate information regarding the subject
        discussed.
        <br />
        13.6 Furthermore, we in no way support any of the services advertised or
        mentioned here. We will never be responsible to You or any other party
        for any decisions or actions taken as a result of using this
        information.
        <br />
        13.7 The information is given "as is" in the following paragraphs. The
        timeliness, content, sequencing, correctness, efficacy, and completeness
        of any information are not guaranteed by us and/or our personnel
        <br />
        13.8 We won't be responsible to You or anyone else for any damages or
        losses resulting from or connected to the data on the platform. Any
        decision or action you take based on the information on the Platform
        will not subject us or our Personnel to liability to you or any third
        party.
        <br /> 13.9 No matter the legal theory (warranty, contract, tort, etc.),
        in no event shall we be liable for any damages (including, without
        limitation, direct, indirect, incidental, special, consequential or
        exemplary damages, damages for personal injury/wrongful death, and
        damages for lost profits, lost data, or business interruption),
        resulting from any services provided by any third party or merchant
        accessed through the platform.
        <br /> 13.10 We reserve the right to do any of the following, at any
        time, without notice: (a) modify, suspend, or terminate operation of or
        access to the Platform, or any portion of the Platform, for any reason
        whatsoever; (b) modify or change the Platform, or any portion of the
        Platform.
        <br /> 13.11 and any applicable policies or terms; or (c) interrupt the
        operation of the Platform, or any portion of the Platform, as necessary
        to perform routine or non-routine maintenance, error correction, or
        other necessary maintenance.
      </div>
      <div className="privacy-subheading">14. Intellectual Property</div>
      <div className="privacy-subheading-content">
        14.1 We either own the intellectual property rights to the Platform and
        the content published on it, including but not limited to the user
        interface, layout format, order placement process flow, ideas, design,
        structure, expression, and any content therein (aside from any content
        posted by the Merchants). We also have the non-exclusive, worldwide,
        perpetual, irrevocable, royalty-free, sub-licensable (through multiple
        tiers) right to exercise those intellectual property rights.
        <br /> 14.2 You acknowledge that the Company is the registered owner of
        the word mark "Digistall" and the logo, including but not limited to its
        variations ("IPR"), and You shall not directly or indirectly challenge
        the validity of, or help a third party challenge the proprietary rights
        of, the Company or its Affiliates in, the licenced marks or any
        registrations thereof, or file a challenge on the basis of any of the
        foregoing.
        <br /> 14.3 Any page(s) from the Platform may be printed off once for
        your own use, as well as excerpts may be downloaded. You may also direct
        individuals in your company to content on the Platform.
        <br /> 14.4 You are not permitted to make any changes to the paper or
        digital copies of any materials you have printed off or downloaded, and
        you are not permitted to use any images, such as photos, videos, or
        audio clips, without the associated text.
        <br /> 14.5 Without first obtaining a license from us or our licensors,
        you are not permitted to utilize any portion of the materials on the
        Platform for commercial endeavours.
        <br /> 14.6 Your right to use the Platform will be violated if You copy,
        print, or download any portion of the Platform in violation of these
        terms. <br />
        14.7 No alterations or modifications of any kind are made to the
        information; no additional statements and warranties with regard to the
        information or documents containing the information shall be made for or
        on our behalf; and no statements and/or notifications indicating our
        ownership of the information and other legal rights and interests in
        relation to the information shall be removed.
      </div>
      <div className="privacy-subheading">15. Payment</div>
      <div className="privacy-subheading-content">
        15.1 The Merchant consents to pay the Company "Transaction Fees" in the
        amount of 2% of the total transaction value for all transactions between
        the Merchant and the Buyer. The Merchant acknowledges that the
        Transaction Fees are subject to change and that any such change will be
        periodically posted on the Platform.
        <br /> 15.2 For the purpose of processing payments made in connection
        with the acquisition of goods provided by Merchants to Buyers, we use
        third-party service providers or payment gateway(s).
        <br /> 15.3 Within two working days of the buyer receiving the good or
        service delivered in accordance with such an Order, the pertinent
        third-party service provider shall transfer the amount paid by the buyer
        against an Order (after deducting the Transaction Fees) into the
        merchant's account.
        <br /> 15.4 Cash payments or credit/debit card payments from the banks
        that are offered when choosing the Pay Facility as the payment method
        are both supported by the Pay Facility. The Pay Facility might accept
        payments made with mobile wallets and UPI. However, payment methods may
        be introduced, deleted, or suspended directly through one or more banks
        or through any payment gateway facilitators, and such a modification
        shall take effect when it is posted on the website of the relevant
        third-party service provider. <br />
        15.5 To facilitate payment, the Buyers will have access to the Pay
        Facility. If the Buyer enters incorrect bank account information, the
        Company will not be held liable for any resulting financial loss.
        <br /> 15.6 While availing any of the payment method/s available on the
        Platform, we will not be responsible or assume any liability, whatsoever
        in respect of any loss or damage arising directly or indirectly to You
        due to:
        <br />
        1. Lack of authorization for any transaction;
        <br />
        2. Exceeding the preset limit mutually agreed by You and between
        "Banks";
        <br />
        3. Any payment issues arising out of the transaction; or
        <br />
        4. Decline of transaction for any other reason.
        <br />
        5. Every payment transaction is subject to Your issuing bank's approval,
        if appropriate. The Merchant shall be entitled to terminate the Order
        without additional liability if Your bank rejects to authorize payment.
        Furthermore, You acknowledge that in the event that Your bank or other
        financial institution delays the payment authorisation, neither We nor
        the Merchants shall be liable for any delivery delays.
        <br />
        6. You understand and agree that you may not use any credit card, debit
        card, net banking, or other payment information that is not legally
        yours.
        <br />
        7. You must make all payments in Indian Rupees or another currency
        recognised in the Republic of India in relation to any purchases or
        services you make through the Platform. The Platform will not allow
        transactions with any other party.
        <br />
        By accepting these Terms, You agree that the Company is an independent
        contractor for all purposes and has no control over or liability for the
        goods or services that are listed on the Platform and purchased using
        the Pay Facility. Your relationship with the Company is one of principal
        to principal. Both the identification of any Buyer and the completion of
        a transaction by either a Buyer or a Merchant are not guaranteed by the
        Company.
        <br />
        You recognise, acknowledge, and agree that the Pay Facility offered by
        the Company is not a banking or financial service but rather only a
        facilitator offering an electronic, automated online payment, receiving
        payment through "cash on delivery," collection, and remittance facility.
      </div>
      <div className="privacy-subheading">16. Payment Facility for Buyers</div>
      <div className="privacy-subheading-content">
        16.1 You, as the Buyer, are aware that when you start a transaction, you
        are doing so with the merchant in order to buy goods and/or services
        from them using the Pay Facility. You will then pay the transaction
        price to the merchant using the Pay Facility through Your issuing bank.
        <br />
        16.2 If the products or services being offered by the Merchant are not
        delivered within the time frame specified in the transaction, You, as
        the Buyer, shall be entitled to demand a refund of the transaction price
        from the Merchant (as Your single and exclusive recourse).
        <br /> 16.3 With the exception of "cash on delivery" transactions, any
        refunds must be processed through the same issuing bank as the
        transaction price was originally paid, unless You opt for another option
        listed on the website of the third-party payment gateway.
        <br /> 16.4 If there are any refunds for "cash on delivery"
        transactions, they will be issued via electronic money transfers in
        compliance with the third-party payment gateway's policies.
        <br /> 16.5 Refunds must only be given in Indian Rupees and match the
        transaction price paid in that currency.
        <br /> 16.6 Refunds are conditional and come with remedies for the
        company in the event that the buyer misuses the money.
        <br /> 16.7 Additional documents may be requested from You by us for
        verification.
        <br /> 16.8 The Company retains the right to impose limits on the number
        of transactions or transaction prices made by a single, valid
        Credit/Debit/Cash Card, a single, valid Bank Account, and any other
        infrastructure or financial instrument, whether directly or indirectly
        through a payment aggregator or through a facility authorised by the
        Reserve Bank of India to provide an enabling support facility for the
        collection and remittance of payment, or by a single Buyer during any
        given time period.
      </div>
      <div className="privacy-subheading">17. Compliance with the laws</div>
      <div className="privacy-subheading-content">
        17.1 Buyer and Merchant shall comply with all the applicable Laws
        (including without limitation Foreign Exchange Management Act, 1999 and
        the rules made and notifications issued thereunder and the Exchange
        Control Manual as may be issued by Reserve Bank of India from time to
        time, Customs Act, Information and Technology Act, 2000 as amended by
        the Information Technology (Amendment) Act 2008, Prevention of Money
        Laundering Act, 2002 and the rules made there under, Foreign
        Contribution Regulation Act, 1976 and the rules made there under, Income
        Tax Act, 1961 and the rules made there under, Export Import Policy of
        the government of India) applicable to them respectively for using the
        payment facility.
      </div>
      <div className="privacy-subheading">
        18. Buyers’ arrangement with issuing banks
      </div>
      <div className="privacy-subheading-content">
        18.1 A payment gateway or appropriate payment system infrastructure is
        used to process all legitimate credit, debit, cash cards, and other
        payment instruments. These are also subject to the terms and conditions
        agreed upon by the buyer and the particular issuing bank and payment
        instrument issuing company.
        <br /> 18.2 The gateway offered by each issuing bank that supports Pay
        Facility is used to handle all online bank transfers from legitimate
        bank accounts in order to offer these services to the Users. The terms
        and conditions established by the buyer and the particular issuing bank
        likewise apply to all such online bank transfers made through Pay
        Facility.
      </div>
      <div className="privacy-subheading">19. Third-Party Links</div>
      <div className="privacy-subheading-content">
        19.1 We disclaim any express or implied warranties or assurances on the
        content, services, and information given on such linked websites to the
        extent that the Platform contains links to advertising banners,
        information about outside services, and resources provided by third
        parties.
        <br /> 19.2 Any issues with any of these services or resources, or any
        links to them, should be brought up with the supplier of that specific
        service or resource. We simply offer these links for the convenience of
        Platform users. You are totally free to browse any or all of the
        websites that are linked, but we will not be held liable for the results
        of any such interactions.
        <br /> 19.3 In no event shall the Company, its Personnel, its suppliers,
        service providers, or internal service providers be liable for any
        direct, indirect, incidental, special, consequential, or exemplary
        damages, including but not limited to damages for loss of profits,
        goodwill, use, data, or other intangible losses arising (in any manner
        whatsoever) out of or in connection with the Platform, the Pay Facility,
        or services rendered by internal service providers on the P's behalf.
        <br />
        19.4 Under no circumstances will the Company be liable for more than You
        have paid in charges or fees, if any. No representations or warranties
        on the accuracy, reliability, completeness, and/or suitability of the
        information are made by the Company, its Personnel, internal service
        providers, or technological partners.
      </div>
      <div className="privacy-subheading">
        20. Identity for non-compliance or breach
      </div>
      <div className="privacy-subheading-content">
        20.1 You shall indemnify and hold harmless the Company and the Company's
        parent, subsidiaries, its Personnel, Affiliates, internal service
        providers and their respective officers, shareholders, directors,
        agents, and employees, from and against any and all Losses, liabilities,
        actions, suits, claims, proceedings, costs, damages, judgments, amounts
        paid in settlement and expenses (including without limitation attorneys'
        fees and disbursements), made by any third party or penalty imposed due
        to or arising out of Your breach of the User Agreement or resulting from
        untrue, inaccurate, misleading not current or incomplete information
        provided or verified by You.
      </div>
      <div className="privacy-subheading">21. Consent and Privacy Policy</div>
      <div className="privacy-subheading-content">
        21.1 You consent to the Company's collection and use of the Information
        you reveal on the Platform and on the Pay Facility (where applicable) by
        using the Platform and/or by disclosing Your Information.
        <br /> 21.2 The personal data that You provide to the Platform or Pay
        Facility (if applicable) while you are a registered user will be kept in
        accordance with the Privacy Policy that is periodically published on the
        Platform and is incorporated herein by reference, as well as any
        applicable laws, including but not limited to the Information Technology
        Act, 2000 and rules thereunder. Please refrain from using the Platform
        if you do not consent to the transfer or use of your information in this
        manner.
      </div>
      <div className="privacy-subheading">22. Termination</div>
      <div className="privacy-subheading-content">
        22.1 Without limiting other remedies the Company may pursue, the Company
        may, in its sole discretion, take any action it deems appropriate,
        including but not limited to restricting Your use of the Platform,
        removing Your information right away, alerting other Users to your
        behaviour, immediately suspending or terminating or blocking your user
        account or membership, and/or refusing to grant you access to the
        Platform.
      </div>
      <div className="privacy-subheading">23. Addressing Grievances</div>
      <div className="privacy-subheading-content">
        23.1 If you have any concerns about the Platform, other users, or the
        Company, or if you have any questions regarding the suspension,
        termination, or blocking of your membership or right to use the
        Platform, you should immediately contact the designated grievance
        officer at support at digistall.in and provide the grievance officer
        with all necessary information and/or documents to enable the
        Company/Grievance to resolve your concern. <br />
        23.2 The Grievance Officer will make an effort to respond to your
        complaints or grievances within 48 (forty-eight) hours after receiving
        them. In order to investigate complaints or grievances and resolve them,
        the grievance officer may need any information he deems necessary. The
        Grievance Officer will make every effort to address the issue.
      </div>
      <div className="privacy-subheading">24. Severability</div>
      <div className="privacy-subheading-content">
        24.1 The remainder of the User Agreement and the application of such
        provision to persons or circumstances other than those as to which it is
        held invalid or unenforceable shall not be affected thereby, and each
        provision of the User Agreement shall remain in full force and effect.
        <br />
        24.2 If any provision of the User Agreement or the application thereof
        to any person or circumstance is determined to be invalid, unlawful,
        void or unenforceable to any extent for any reason, including by reason
        of any applicable Law, Any part of the User Agreement that is invalid or
        unenforceable must be replaced with a clause that most closely matches
        its original intent while still being valid and enforceable.
      </div>
      <div className="privacy-subheading">25. Governing Laws</div>
      <div className="privacy-subheading-content">
        25.1 In line with Indian law, the User Agreement and other documents
        included by reference will be interpreted. The senior management of the
        Company will be consulted if a dispute between You and the Company
        arises during Your use of the Platform, the Pay Facility, or any service
        related to the Platform, or after regarding the legality,
        interpretation, implementation, or alleged breach of any provision of
        the Terms and/or any other agreement between You and the Company aside
        from the User Agreement and any other documents incorporated therein by
        reference. If the disagreement has not been settled, Gotan, Rajasthan's
        courts will have sole jurisdiction over the matter.
      </div>
    </div>
  );
};

export default TermsAndConditions;
