import React, { useRef, useEffect } from "react";
import EmitraCounter from "../utils/emitraCounter";

import mylogo from "../../images/mylogo.png";
import HCrousal from "../StaticHomeComponents/homeCroussal";
import { Link } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { IoPersonCircleOutline } from "react-icons/io5";
import { IoCartOutline } from "react-icons/io5";
import { HiOutlineGlobe } from "react-icons/hi";
import { TbHandClick } from "react-icons/tb";
import { IoPlanetOutline } from "react-icons/io5";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { FaLink } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import "./LandingPage.scss";
import emitraLogo from "../../images/emitraLogo.jpg";
import { useNavigate } from "react-router-dom";
import { IoMail } from "react-icons/io5";
import { MdOutlineInventory } from "react-icons/md";
import Download from "./download";
import herovideo from "../StaticHomeComponents/herovideo.mp4";
import Radio from "../utils/radio";
import InstallButton from "../utils/pwa";
import TrainingDownload from "./trainingDownload";
import ParallaxImages from "./parallex";

const LandingPage = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const handleWhatsappClick = () => {
    const whatsappNumber = "9461852060";
    window.open(`https://wa.me/${whatsappNumber}`, "_blank");
  };
  useEffect(() => {
    const video = videoRef.current;

    const handleEnded = () => {
      video.play();
    };

    video.addEventListener("ended", handleEnded);

    return () => {
      video.removeEventListener("ended", handleEnded);
    };
  }, []);

  return (
    <div className="myHome">
      <div className="fixed bottom-0 right-0 m-4 z-50">
        <div className="flex justify-center items-center">
          <FaWhatsapp
            size={30}
            className="cursor-pointer text-green-500"
            onClick={handleWhatsappClick}
          />
          <div>Helpline</div>
        </div>
      </div>

      <nav className="mynavbar">
        <div className="logo flex flex-row">
          <img src={mylogo} width={100} className="hidden md:visible" />
          <img
            src={emitraLogo}
            width={40}
            height={40}
            alt="emitralogo"
            className="emitra-logo"
          />
        </div>
        <div className="text-sm font-bold">Helpline: 9461852060 </div>
        <div className="button-container">
          <button className="buttonLogin">
            <Link
              to={"https://admin.digistall.in/login/phone"}
              className="loginlink"
            >
              Log In
            </Link>
          </button>
        </div>
      </nav>
      <div className="Main">
        {" "}
        <div className="emitratandc-guide">
          <div>
            <button
              id="emitra-t-c-bttn"
              onClick={() => navigate("emitra_terms_and_conditions")}
            >
              E-mitra Terms and Conditions
            </button>
          </div>
          <div>
            <Download />
          </div>
        </div>
        <div className="HeroSection">
          <div className="heroimg">
            <video
              ref={videoRef}
              className="herolaptopimahe"
              width={480}
              height={300}
              // controls
              // loop
              loop
              muted
              autoPlay
            >
              <source src={herovideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="herotxt">
            <p className="herotxt-op herotxt-p"> Your Gateway to E-Commerce</p>
            <p>Create your own online store with ease. No coding required.</p>
            <p>Free 7 day trial for all users</p>

            <button className="buttonSignupok" id="getstarted-bttn">
              <Link
                to={"https://sso.rajasthan.gov.in/signin"}
                className="getstartedlink "
              >
                Login With SSO
              </Link>
            </button>
            <button className="buttonSignupok" id="getstarted-bttn">
              <InstallButton />
            </button>
            <button className="buttonSignupok" id="getstarted-bttn">
              <TrainingDownload />
            </button>
          </div>
        </div>
      </div>

      {/* <div className="head radio-txt">Radio</div>
        <div className="radioblock">
          <Radio />
        </div> */}

      {/* <div className="Start">
          <div className="txt">Getting Started with 4 Steps</div>
          <div className="card-container-steps">
            <div className="card1-steps">
              <div classname="cart-steps-innerdiv">
                <div className="profile-icon">
                  <IoPersonCircleOutline />
                </div>
                <div>
                  <b>Sign Up</b>
                </div>
                <div>
                  <p>Create your account</p>
                </div>
              </div>
            </div>
            <div className="card2-steps">
              <div classname="cart-steps-innerdiv">
                <div className="color-board">
                  <FaLink />
                </div>
                <div>
                  <b>Choose a domain</b>
                </div>
                <div>
                  <p>Create your own domain.</p>
                </div>
              </div>
            </div>
            <div className="card3-steps">
              <div classname="cart-steps-innerdiv">
                <div className="cart-logo">
                  <IoCartOutline />
                </div>
                <div>
                  <b>Add products</b>
                </div>
                <div>
                  <p>Upload your items</p>
                </div>
              </div>
            </div>
            <div className="card4-steps">
              <div classname="cart-steps-innerdiv">
                <div className="globe-logo">
                  <HiOutlineGlobe />
                </div>
                <div>
                  <b>Go Live</b>
                </div>
                <div>
                  <p>Start Selling</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      {/* <div className="bg-[#d9bda5] flex items-center justify-center mt-2 p-4">
        <div className="bg-[#e5cbba] text-black shadow-lg rounded-lg p-8  w-full ">
          <div className="text-center mb-6">
            <h1 className="text-2xl font-bold">
              प्रिय ई-मित्र ऑपरेटर्स, नमस्कार!
            </h1>
          </div>
          <p className="mb-4">
            हमें गर्व है कि हम आपको Digistall के बारे में बता रहे हैं, जो उभरते
            हुए उद्यमियों और छोटे व्यवसायियों को सशक्त बनाने के लिए समर्पित है।
            Digistall पर कोई भी व्यापारी अपने व्यवसाय की ऑनलाइन वेबसाइट बना सकता
            है। हमारा no-coding वेबसाइट बिल्डर बहुत ही सरल और सहज है, जिससे बिना
            किसी तकनीकी ज्ञान के भी वेबसाइट बनाना बेहद आसान है।
          </p>
          <p className="mb-4">
            अब Digistall की सेवा ई-मित्र पोर्टल पर उपलब्ध है, जिससे आप दूसरों के
            व्यवसाय की वेबसाइट बनाकर आर्थिक लाभ कमा सकते हैं। प्रत्येक वेबसाइट
            के लिए व्यवसायी से 3000 रुपये का टोकन शुल्क लिया जाएगा, जिसमें से
            आपको 1200 रुपये का कमीशन प्राप्त होगा।
          </p>
          <p className="mb-4">
            उदाहरण के तौर पर, एक ई-मित्र ऑपरेटर मेहुल, जो एक वेबसाइट बनाकर 1200
            रुपये कमा सकते हैं। अगर वे 10 वेबसाइट बनाते हैं, तो 12000 रुपये तक
            कमा सकते हैं। यह आपके लिए एक अतिरिक्त आय का स्रोत है।
          </p>
          <p className="mb-4">
            हम ई-मित्र ऑपरेटरों को Digistall पर छोटे व्यापार मालिकों के लिए
            स्टोर बनाने के लिए प्रतिदिन सोमवार से शनिवार शाम 5 बजे ऑनलाइन
            प्रशिक्षण दे रहे हैं। आप इस Training Gmeet Link लिंक पर जुड़ सकते
            हैं। हमारे प्रशिक्षण सत्रों में, हम आपको सिखाएंगे कि कैसे ई-मित्र
            पोर्टल का उपयोग करके Digistall पर एक स्टोर बनाया जाए, व्यापार
            मालिकों को ऑनबोर्ड कैसे करें, और उन्हें उनकी वेबसाइट बनाने के लिए
            कैसे प्रेरित करें।
          </p>
          <p className="mb-4">
            ध्यान दें, कोई भी दुकानदार खुद चलकर आपके पास नहीं आएगा, आपको ही
            उन्हें Digistall के उपयोग और ईकॉमर्स के महत्व को समझाना होगा। जब वे
            छह महीने बाद अपनी सदस्यता का नवीनीकरण करेंगे, तो आपको फिर से आपका
            कमीशन प्राप्त होगा।
          </p>
          <p>
            हम सभी ई-मित्र ऑपरेटर्स से अनुरोध करते हैं कि हमारे इस ई-कॉमर्स की
            क्रांति में शामिल हों और इस अद्भुत अवसर का लाभ उठाएं।
          </p>
        </div>
      </div> */}
      <div className="bg-[#d9bda5] flex items-center justify-center mt-2 p-4">
        <div className="bg-[#e5cbba] text-black shadow-lg rounded-lg p-8 w-full hindifont">
          <div className="text-center mb-6">
            <h1 className="text-2xl font-bold ">
              प्रिय ई-मित्र ऑपरेटर्स, नमस्कार!
            </h1>
          </div>
          <p className="mb-4">
            हमें गर्व है कि हम आपको{" "}
            <span className="font-bold text-[#0293A8]">Digistall</span> के बारे
            में बता रहे हैं, जो उभरते हुए उद्यमियों और छोटे व्यवसायियों को सशक्त
            बनाने के लिए समर्पित है।
            <span className="font-bold">
              Digistall पर कोई भी व्यापारी अपने व्यवसाय की ऑनलाइन वेबसाइट बना
              सकता है।
            </span>
            हमारा no-coding वेबसाइट बिल्डर बहुत ही सरल और सहज है, जिससे बिना
            किसी तकनीकी ज्ञान के भी वेबसाइट बनाना बेहद आसान है।
          </p>
          <p className="mb-4">
            अब{" "}
            <span className="font-bold text-[#0293A8]">
              Digistall की सेवा ई-मित्र पोर्टल पर उपलब्ध है
            </span>
            , जिससे आप दूसरों के व्यवसाय की वेबसाइट बनाकर आर्थिक लाभ कमा सकते
            हैं। प्रत्येक वेबसाइट के लिए व्यवसायी से{" "}
            <span className="font-bold text-[#FF0000]">3000 रुपये</span> का टोकन
            शुल्क लिया जाएगा, जिसमें से आपको{" "}
            <span className="font-bold text-[#FF0000]">1200 रुपये</span> का
            कमीशन प्राप्त होगा।
          </p>
          <p className="mb-4">
            उदाहरण के तौर पर, एक ई-मित्र ऑपरेटर मेहुल, जो एक वेबसाइट बनाकर 1200
            रुपये कमा सकते हैं। अगर वे 10 वेबसाइट बनाते हैं, तो 12000 रुपये तक
            कमा सकते हैं। यह आपके लिए एक अतिरिक्त आय का स्रोत है।
          </p>
          <p className="mb-4">
            हम ई-मित्र ऑपरेटरों को Digistall पर छोटे व्यापार मालिकों के लिए
            स्टोर बनाने के लिए प्रतिदिन सोमवार से शनिवार शाम 5 बजे ऑनलाइन
            प्रशिक्षण दे रहे हैं। आप इस{" "}
            <span href="#" className=" text-[#0293A8]">
              Training Gmeet Link
            </span>{" "}
            लिंक पर जुड़ सकते हैं। हमारे प्रशिक्षण सत्रों में, हम आपको सिखाएंगे
            कि कैसे ई-मित्र पोर्टल का उपयोग करके Digistall पर एक स्टोर बनाया
            जाए, व्यापार मालिकों को ऑनबोर्ड कैसे करें, और उन्हें उनकी वेबसाइट
            बनाने के लिए कैसे प्रेरित करें।
          </p>
          <p className="mb-4">
            ध्यान दें, कोई भी दुकानदार खुद चलकर आपके पास नहीं आएगा, आपको ही
            उन्हें{" "}
            <span className="font-bold text-[#0293A8]">
              Digistall के उपयोग और ईकॉमर्स के महत्व
            </span>{" "}
            को समझाना होगा। जब वे छह महीने बाद अपनी सदस्यता का नवीनीकरण करेंगे,
            तो आपको फिर से आपका कमीशन प्राप्त होगा।
          </p>
          <p>
            हम सभी ई-मित्र ऑपरेटर्स से अनुरोध करते हैं कि हमारे इस ई-कॉमर्स की
            क्रांति में शामिल हों और इस अद्भुत अवसर का लाभ उठाएं।
          </p>
        </div>
      </div>

      <div className="Main ">
        <div className="Subs">
          <div className="txt">Subscription Plans</div>
          <div className="card-container-subs">
            <div className="card2-subs">
              <div className="first-div">
                <div id="pro-tag">
                  <p>E-Mitra Comission</p>
                </div>
                <div id="price-monthly">
                  <div className="tendollar">₹2000</div>
                  <div className="permoth">for 6 Month</div>
                </div>
                <div className="">Emitra Commsision ₹1200</div>

                <div className="">Renew after 6 months</div>
                <div className="choose-basic">Choose Pro</div>
              </div>
            </div>
            {/* <div className="card3-subs">
              <div className="first-div">
                <div id="premium-tag">
                  <p>Premium</p>
                </div>
                <div id="price-monthly">
                  <div className="tendollar">₹6000</div>
                  <div className="permoth">for 12 Months</div>
                </div>
                <div className="choose-basic">Choose Premium</div>
                <div id="bulletpoints-card1">
                  <p>
                    Unlimited Storage
                  </p>
                  <p>
                    Advance analytics
                  </p>
                  <p>
                    24/7 Support
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* Features */}
      {/* <div className="Feature">
          <div className="txt">FEATURES</div>
          <div className="txt1">Everything you need at your fingertips</div>
          <div className="txt2">
            Simplify the process of building and managing your online store with
            our comprehensive set of tools and features.
          </div>
          <div className="card-container-feature">
            <div className="card1-feature">
              <div className="firstdiv">
                <div className="finger-click-icon">
                  <TbHandClick />
                </div>
                <div className="feature-title">
                  <b>Bill Book</b>
                </div>
                <div>
                  <p>
                    Create your own Bill Book to know your exact transaction.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="card2-feature">
              <div className="firstdiv">
                <div className="planet-icon">
                  <IoPlanetOutline />
                </div>
                <div className="feature-title">
                  <b>Custom Domains</b>
                </div>
                <div>
                  <p>Establish your brand with a custom domain.</p>
                </div>
              </div>
            </div>
            <div className="card3-feature">
              <div className="firstdiv">
                <div className="planet-icon">
                  <AiOutlineExclamationCircle />
                </div>
                <div className="feature-title">
                  <b>24/7 Customer Support</b>
                </div>
                <div>
                  <p>
                    Get help whenever you need it with round-the-clock
                    assistance.
                  </p>
                </div>
              </div>
            </div>
            <div className="card3-feature">
              <div className="firstdiv">
                <div className="planet-icon">
                  <MdOutlineInventory />
                </div>
                <div className="feature-title">
                  <b>Inventory Management</b>
                </div>
                <div>
                  <p>
                    Hold your ultimate goal of resale, production or
                    utilisation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      {/* <div className="About">
          <div className="txt">ABOUT US</div>
          <div className="txt1">
            Digistall is dedicated to empowering entrepreneurs and business
            owners by providing an accessible platform to create and grow their
            e-commerce presence without the need for technical skills.
          </div>
          <div>
            <HCrousal />
          </div>
        </div> */}

      <ParallaxImages />
      <div className="mb-2 mt-2">
        <EmitraCounter />
      </div>
      <div className="Main">
        <div className="Footer">
          <div className="footer1">
            <div>
              <Link
                to={"https://digistall.in/privacy_and_policy"}
                className="privacy-policy"
              >
                Privacy Policy
              </Link>
            </div>
            <div>
              <Link
                to={"https://digistall.in/terms_and_conditions"}
                className="terms-and-service"
              >
                Terms of Service
              </Link>
            </div>
            <div>
              <Link
                to={"https://digistall.substack.com/"}
                className="news-letter"
              >
                Newsletter
              </Link>
            </div>
          </div>
          <div className="footer2">
            <div id="facebook-link">
              <a href="https://www.facebook.com/humansofruralindia/">
                <FaFacebook />
              </a>
            </div>
            <div id="twitter-link">
              <a href="https://www.linkedin.com/company/humans-of-rural-india/">
                <FaLinkedin />
              </a>
            </div>
            <div id="insta-link">
              <a href="https://www.instagram.com/digistallapp/">
                <FaInstagramSquare />
              </a>
            </div>
          </div>
          <div className="footer4">
            <div id="mail-name-id-div">
              <div id="footer-inner-div">
                <a href="mailto:humansofruralindia@gmail.com">
                  <i>
                    <IoMail />
                  </i>{" "}
                  humansofruralindia@gmail.com{" "}
                </a>
              </div>

              {/* <div>7 , Siyag House Gotan Tehsil Merta 342902</div> */}
              <div>Siyag Rural Market Private Limited</div>
            </div>
            <div id="cin-gst-id-div">
              <div>CIN No. :- U52100RJ2021PTC077111</div>
              <div>GSTIN :- 08ABGCS8909L1ZN</div>
            </div>
          </div>
          <div className="footer3">© 2024 Digistall. All rights reserved.</div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
