import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { getSubdomains } from "./fetchCalls/fetchRoutes";
import LoginSheredLayout from "./components/Login/LoginLayout";
import LoginBtns from "./components/Login/loginBtns";
import EmailLogin from "./components/Login/EmailContainer";
import SignupSheredLayout from "./components/SignUp/signupSheredLayout";
import SignupBtns from "./components/SignUp/signupBtns";
import EmailSignup from "./components/SignUp/signupContainer";
import Wishlist from "./components/Wishlist/Wishlist";
import History from "./components/History/History";
import Loader from "./components/Loading";
import Cart from "./components/Cart/cart";
import "./App.css";
import NotFound from "./components/PageNotFound/pageNotFound";
import { useSelector } from "react-redux";
import { intlFormatDistance } from "date-fns";
import Blocked from "./components/blocked/blocked";
import { getStoreData } from "./fetures/store/storeSlice";
import { useDispatch } from "react-redux";
import LandingPage from "./components/Emitra/LandingPage";
import Dashboard from "./components/Emitra/Dashboard";
import PrivacyAndPolicy from "./components/StaticHomeComponents/PrivacyAndPolicy";
import TermsAndConditions from "./components/StaticHomeComponents/TermsAndConditions";
import EmitraTermsAndConditions from "./components/Emitra/EmitraTermsAndConditions";
import Invoice from "./components/Emitra/Invoice";
import Header from "./components/Header/header";
import PaidUsers from "./components/PaidUsers/PaidUsers";
import PhoneSignIn from "./components/SignUp/PhoneSignIn";
import MarketPlace from "./components/Marketplace/layout";
import BlogPage from "./blog/blogPage";
import BlogPage2 from "./blog/blogPage2";
import AdminBlog from "./blog/adminBlog";
import AllBlogs from "./blog/allBlogs";
// Seller Blogs
import SellerAllBlogs from "./blog/seller/allBlogs";
import OneBlog from "./blog/seller/oneBlog";
import CustomerLogin from "./customerLogin/cutomerLogin";
import CustomerLogin2 from "./customerLogin/customerLogin2";
import CustomerRiderection from "./customerLogin/customerRiderection";

const SharedLayout = React.lazy(() =>
  import("./components/SharedLayout/sharedLayout")
);
const Product = React.lazy(() => import("./components/Product/product"));
const Profile = React.lazy(() => import("./components/Profile/profile"));
const StaticHome = React.lazy(() =>
  import("./components/StaticHomeComponents/StaticHome")
);
const Home = React.lazy(() => import("./components/Home/home"));
const PrivacyPolicy = React.lazy(() =>
  import("./components/StaticHomeComponents/PrivacyPolicy")
);
const ContactUs = React.lazy(() =>
  import("./components/StaticHomeComponents/Home/ContactUs")
);
const Terms = React.lazy(() => import("./components/Terms/terms"));
const Msme = React.lazy(() => import("./components/msme/msme"));
const Fsign = React.lazy(() => import("./components/SignUp/Fsign"));
const Flogin = React.lazy(() => import("./components/SignUp/Flogin"));
const Phone = React.lazy(() => import("./components/SignUp/phone"));
const Emitraguide = React.lazy(() => import("./components/Emitra/download"));
const Partnerprogram = React.lazy(() =>
  import("./components/StaticHomeComponents/partnerprogram")
);
const Rajeevika = React.lazy(() => import("./components/Rajeevika/layout"));
const Ruda = React.lazy(() => import("./components/ruda/layout"));
const Jkk = React.lazy(() => import("./components/jkk/layout"));
const Ebazar = React.lazy(() => import("./components/ebazaar/layout"));
const WaterShred = React.lazy(() => import("./components/waterShred/layout"));
const Fpo = React.lazy(() => import("./components/fpo/layout"));
const Radio = React.lazy(() => import("./components/radio/radio"));
const Government = React.lazy(() => import("./components/government/layout"));
const Amazon = React.lazy(() => import("./amazon/amazon"));
const AmazonProduct = React.lazy(() => import("./amazon/landingProducts"));

const SuppertAdmin = React.lazy(() => import("./admin/comp/users"));
// Service Page
// const ServiceLayout = React.lazy(() => import("./Service/serviceLayout"));
// const SingleProduct = React.lazy(() => import("./Service/singleProducts"));
// const ServiceProfile = React.lazy(() => import("./Service/serviceProfile"));

//@ Rajeevik Admin
const RajeevikaAdmin = React.lazy(() =>
  import("./components/rajeevikaAdmin/login")
);
const RajeevikaAdminDashboard = React.lazy(() =>
  import("./components/rajeevikaAdmin/layout")
);
const RajeevikaShop = React.lazy(() =>
  import("./components/rajeevikaAdmin/shop")
);
const ShopOrder = React.lazy(() =>
  import("./components/rajeevikaAdmin/shopOrders")
);

const MusicLayout = React.lazy(() => import("./components/radio/layout"));
const MusicHome = React.lazy(() => import("./components/radio/home"));
const RajMusic = React.lazy(() => import("./components/radio/music"));
let landingaPage = null;

const host = window.location.host; // gets the full domain of the app
let arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);
// if (arr.length > 0) landingaPage = arr[0];

// console.log("landingaPage", arr);

// Handle 'www' as a special case
if (arr[0] === "www") {
  arr = [];
}

if (arr.length > 0) {
  landingaPage = arr[0];
}

const loadingConst = {
  loading: "LOADING",
  success: "SUCCESS",
  failed: "FAILED",
};

function App() {
  const shopType = useSelector(
    (state) => state.store.storeData?.shopType || "product"
  );

  const [subdomain, setSubdomain] = useState();
  const [loadingStatus, setStatus] = useState("LOADING");
  const [subdomains, setSubdomains] = useState([]);
  const { storeData } = useSelector((state) => state.store);
  // let validity;
  // if (storeData !== null) {
  //   const { validTill } = storeData;
  //   validity = validTill;
  // }
  const dispatch = useDispatch();
  // let validUntill = "days";
  // if (validity) {
  //   validUntill = intlFormatDistance(new Date(validity), new Date(), {
  //     numeric: "always",
  //     unit: "day",
  //   }).split(" ")[1];
  // }

  const getSubdomainData = async () => {
    setStatus("LOADING");
    try {
      const subdomainsresponse = await getSubdomains();
      const subdomainsArray = subdomainsresponse.data.response.map(
        (each) => each.subdomain
      );
      setSubdomains(subdomainsArray);
      setStatus("SUCCESS");
    } catch (e) {
      setStatus("FAILED");
    }
  };

  const getDomainDetails = async () => {
    setStatus("LOADING");
    await getSubdomainData();
    const host = window.location.host; // gets the full domain of the app
    const arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);

    // Handle 'www' as a special case
    if (arr[0] === "www") {
      arr = [];
    }

    if (arr.length > 0) {
      dispatch(getStoreData(arr[0]));
      setSubdomain(arr[0]);
    }
  };

  useEffect(() => {
    if (landingaPage) {
      getDomainDetails();
    }
  }, []);

  const renderFailureView = () => (
    <div className="error-container">
      <h1 className="error-h">Something Was Wrong Please Tryagain</h1>
      <button
        type="button"
        className="try-again-btn"
        onClick={getSubdomainData}
      >
        Try Again
      </button>
    </div>
  );
  const renderSubdomains = () => {
    console.log(shopType);
    if (subdomains.includes(subdomain)) {
      return (
        <React.Suspense
          fallback={
            <div className="loading-container">
              <Loader />
            </div>
          }
        >
          {/* <Routes> */}
          {/* {validUntill > 0 ? */}
          <>
            {/* {shopType && shopType === "service" ? (
              <Routes>
                <Route path="/" element={<ServiceLayout />} />
                <Route path="/service/:id" element={<SingleProduct />} />
                <Route path="/profile" element={<ServiceProfile />} />
                <Route path="/cart" element={<Cart />} />
              </Routes>
            ) : ( */}
            <Routes>
              <Route path="/" element={<SharedLayout />}>
                <Route index element={<Home />} />
                <Route path="product/:id" element={<Product />} />
                <Route path="profile" element={<Profile />} />
              </Route>
              <Route path="/wishlist" element={<Wishlist />} />
              <Route path="/history" element={<History />} />

              <Route path="/cart" element={<Cart />} />
              <Route path="/login" element={<LoginSheredLayout />}>
                <Route index element={<EmailLogin />} />
                {/* <Route path='email' element={<EmailLogin />} /> */}
              </Route>
              <Route path="/signup" element={<SignupSheredLayout />}>
                <Route index element={<EmailSignup />} />
                {/* <Route index element={<PhoneSignIn />} /> */}
                {/* <Route path='email' element={<EmailSignup />} /> */}
              </Route>
              <Route path="/terms" element={<Terms />} />
              <Route path="/msme" element={<Msme />} />

              <Route path="/phone" element={<Phone />}>
                <Route index element={<Fsign />} />
                <Route path="login" element={<Flogin />} />
              </Route>

              <Route path="/blogs" element={<SellerAllBlogs />} />
              <Route path="/blog/:slug" element={<OneBlog />} />
              <Route path="/customerLogin" element={<CustomerLogin2 />} />
              <Route path="/customer" element={<CustomerRiderection />} />
            </Routes>
            {/* )} */}
          </>
          {/* : <Route path='/*' element={<Blocked />} />} */}
          {/* </Routes> */}
        </React.Suspense>
      );
    } else {
      return (
        <React.Suspense
          fallback={
            <div className="loading-container">
              <Loader />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<NotFound />} />
          </Routes>
        </React.Suspense>
      );
    }
  };

  const renderSuccessView = () => {
    return renderSubdomains();
  };

  const renderLoadingView = () => (
    <div className="loading-container">
      <Loader />
    </div>
  );
  const renderStore = () => {
    switch (loadingStatus) {
      case loadingConst.loading:
        return renderLoadingView();
      case loadingConst.success:
        return renderSuccessView();
      case loadingConst.failed:
        return renderFailureView();
      default:
        break;
    }
  };
  return (
    <>
      {!landingaPage ? (
        <React.Suspense
          fallback={
            <div className="loading-container">
              <Loader />
            </div>
          }
        >
          <Routes>
            <Route path="/">
              {/* <Route path='/' element={<SharedLayout />} > */}
              {/* <Route index element={<PhoneSignIn />} /> */}
              <Route index element={<StaticHome />} />
              <Route path="/partnerprogram" element={<Partnerprogram />} />
              <Route path="policy" element={<PrivacyPolicy />} />
              <Route path="contactus" element={<ContactUs />} />
              <Route path="emitra" element={<LandingPage />} />
              <Route path="emitra/dashboard" element={<Dashboard />} />
              {/* <Route path="/paidusers" element={<PaidUsers />} /> */}
              {/* Rajeevika  */}
              {/* <Route path="/Rajeevika" element={<Rajeevika />} /> */}
              <Route path="/rajeevika" element={<Amazon />} />
              <Route path="/rajeevika/products" element={<AmazonProduct />} />

              <Route path="/ruda" element={<Ruda />} />
              <Route path="/istart" element={<Ruda />} />
              <Route path="/lalitkala" element={<Ruda />} />
              <Route path="/amrita" element={<Ruda />} />
              <Route path="/khadigramdyoye" element={<Ruda />} />
              <Route path="/jkk" element={<Amazon />} />
              <Route path="/ebazaar" element={<Ebazar />} />
              <Route path="/handloom" element={<Amazon />} />
              <Route path="/rajasthanfpo" element={<Fpo />} />

              {/* <Route path="/radio" element={<Radio />} /> */}

              <Route
                path="emitra/emitra_terms_and_conditions"
                element={<EmitraTermsAndConditions />}
              />
              <Route path="/emitra/guide" element={<PrivacyAndPolicy />} />
              <Route path="/emitra/Emitra-guide" element={<Emitraguide />} />
            </Route>
            <Route path="/privacy_and_policy" element={<PrivacyAndPolicy />} />
            <Route path="/hyperlocal" element={<MarketPlace />} />
            <Route path="/marketplace" element={<Government />} />
            <Route path="/radio" element={<MusicLayout />}>
              {/* <Route index element={<MusicHome />} /> */}
              {/* <Route path="emitra" element={<Radio />} /> */}
              {/* <Route path="rajasthanimusic" element={<RajMusic />} /> */}
            </Route>

            <Route
              path="/terms_and_conditions"
              element={<TermsAndConditions />}
            />
            {/* <Route path="/blogs" element={<BlogPage />} /> */}
            <Route path="/blogs" element={<AllBlogs />} />
            {/* Json Blogs */}
            <Route path="/blog/:slug" element={<BlogPage2 />} />
            <Route path="/addBlog" element={<AdminBlog />} />
            <Route path="/rajkhadi" element={<Amazon />} />
            <Route path="/customerLogin" element={<CustomerLogin />} />
            {/* Rajeevika Admin */}
            <Route path="/Admin" element={<RajeevikaAdmin />} />
            <Route path="/masteradmin" element={<RajeevikaAdminDashboard />} />
            <Route path="/masteradmin/shop" element={<RajeevikaShop />} />
            <Route path="/masteradmin/shop/:shopId" element={<ShopOrder />} />
            <Route
              path="/masteradmin/supportadmin"
              element={<SuppertAdmin />}
            />
          </Routes>
          {/* Blogs */}
        </React.Suspense>
      ) : (
        renderStore()
      )}
    </>
  );
}

export default App;
