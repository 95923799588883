import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  adminData: {},
  isLoggedIn: false,
};

const loadState = () => {
  let state;
  try {
    const serialisedState = window.localStorage.getItem("hri_local_admin");
    if (!serialisedState) {
      state = defaultState;
      const serialisedState2 = JSON.stringify(state);
      window.localStorage.setItem("hri_local_admin", serialisedState2);
    } else {
      state = JSON.parse(serialisedState);
    }
  } catch (err) {
    console.log("error");
  }
  return state;
};

const initialState = loadState();

const adminSlice = createSlice({
  name: "rajeevika",
  initialState,
  reducers: {
    setAdminData: (state, action) => {
      const adminData = action.payload;
      state.adminData = adminData;
    },
    login: (state) => {
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.adminData = {};
      state.isLoggedIn = false;
    },
  },
});

export const { setAdminData, login, logout } = adminSlice.actions;

export default adminSlice.reducer;
