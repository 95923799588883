// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import DigistallLogo from "./../images/image 4digistall_logo.png";
// import { Link } from "react-router-dom";

// function AllBlogs() {
//   const [blogs, setBlogs] = useState([]);

//   useEffect(() => {
//     // Fetch blogs from the API
//     const fetchBlogs = async () => {
//       try {
//         const response = await axios.get(
//           `https://gotanbackend.digistall.in/api/blog/titlesandimages`
//         );
//         setBlogs(response.data);
//       } catch (error) {
//         console.error("Error fetching blogs:", error);
//       }
//     };

//     fetchBlogs();
//   }, []);

//   return (
//     <div className="max-w-7xl mx-auto px-4 pt-2 pb-2">
//       <div className="flex flex-row gap-3 my-4">
//         <Link to={"https://digistall.in/"}>
//           <img
//             src={DigistallLogo}
//             className="w-[40px] h-[40px]"
//             alt="Digistall Logo"
//           />
//         </Link>
//         <div className="py-2 merriweather-bold">Digistall</div>
//       </div>
//       <h1 className="text-3xl font-bold mb-6">All Blogs</h1>
//       <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
//         {blogs.map((blog) => (
//           <div
//             key={blog.slug}
//             className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg transition-shadow duration-300"
//           >
//             <img
//               src={blog.coverImage}
//               alt={blog.title}
//               className="w-full h-48 object-cover"
//             />
//             <div className="p-4">
//               <h2 className="text-xl font-semibold mb-2">{blog.title}</h2>
//               <a
//                 href={`/blog/${blog.slug}`}
//                 className="text-blue-500 hover:underline"
//               >
//                 Read More
//               </a>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default AllBlogs;

import React, { useState, useEffect } from "react";
import axios from "axios";
import DigistallLogo from "./../images/image 4digistall_logo.png";
import { Link } from "react-router-dom";

function AllBlogs() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    // Fetch blogs from the API
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          `https://gotanbackend.digistall.in/api/blog/titlesandimages`
        );
        setBlogs(response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-4 pt-2 pb-2">
      <div className="flex flex-row gap-3 my-4">
        <Link to={"https://digistall.in/"}>
          <img
            src={DigistallLogo}
            className="w-[40px] h-[40px]"
            alt="Digistall Logo"
          />
        </Link>
        <div className="py-2 merriweather-bold">Digistall</div>
      </div>
      <h1 className="text-3xl font-bold mb-6">All Blogs</h1>
      <div className="space-y-6">
        {blogs.map((blog) => (
          <div
            key={blog.slug}
            className="flex flex-col md:flex-row bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg transition-shadow duration-300"
          >
            <div className="flex-1 p-4">
              <h2 className="text-xl font-semibold mb-2">
                <a href={`/blog/${blog.slug}`}>{blog.title}</a>
              </h2>
              <div className="text-sm text-gray-500">
                {/* <span>{new Date(blog.date).toLocaleDateString()}</span> •{" "} */}
                <span>• Digistall</span>
                {blog.shopId ? (
                  <span>• {blog.shopId}</span>
                ) : (
                  <span>• Digistall</span>
                )}
              </div>
            </div>
            <div className="w-full md:w-[200px] h-[150px] md:h-auto">
              <img
                src={blog.coverImage}
                alt={blog.title}
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AllBlogs;
