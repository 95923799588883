import { MdOutlineFileDownload } from "react-icons/md";
import file from "./somcollection.apk";
const SomCollection = () => {
  return (
    <>
      <a
        href={file}
        download
        className={`flex flex-row bg-[#0293a8] mr-2 rounded-md h-max px-2 py-2 mt-1 text-white`}
      >
        App <MdOutlineFileDownload size={20} />
      </a>
    </>
  );
};

export default SomCollection;
