import React from "react";
import "./privacy.scss";
const PrivacyAndPolicy = () => {
  return (
    <div className="privacy">
      <div className="privacy-heading">Privacy Policy</div>
      <hr className="privacy-horizontal"/>
      <div className="privacy-subheading">
        1. WHAT DO WE DO WITH YOUR INFORMATION:
      </div>
      <div className="privacy-subheading-content">
        When you purchase something from our store, as part of the buying and
        selling process, we collect the personal information you give us such as
        your name, address and email address.
        <br />
        When you browse our store, we also automatically receive your computer’s
        internet protocol (IP) address in order to provide us with information
        that helps us learn about your browser and operating system.
        <br />
        When you browse our store, we also automatically receive your computer’s
        internet protocol (IP) address in order to provide us with information
        that helps us learn about your browser and operating system.When you
        browse our store, we also automatically receive your computer’s internet
        protocol (IP) address in order to provide us with information that helps
        us learn about your browser and operating system.
      </div>
      <div className="privacy-subheading">2. CONSENT:</div>
      <div className="privacy-subheading-subques">
        How do you get my consent?
      </div>
      <div className="privacy-subheading-content">
        When you provide us with personal information to complete a transaction,
        verify your credit card, place an order, arrange for a delivery or
        return a purchase, we imply that you consent to our collecting it and
        using it for that specific reason only.
        <br />
        If we ask for your personal information for a secondary reason, like
        marketing, we will either ask you directly for your expressed consent or
        provide you with an opportunity to say no. Phone number shared by the
        user through the website, exhibitions, pop-ups, and social media for the
        purpose of receiving information, talking to design experts and
        receiving marketing material implies the user has provided consent to be
        contacted by Phone, SMS, or Whatsapp. If a user wishes to be
        specifically contacted via any one preferred channel, Typof will be
        obliged to do so.
        <br />
      </div>
      <div className="privacy-subheading-subques">
        How do I withdraw my consent?
      </div>
      <div className="privacy-subheading-content">
        If after you opt-in, you change your mind, you may withdraw your consent
        for us to contact you, for the continued collection, use or disclosure
        of your information, at any time, by contacting us at <b>9461852060</b>
        or emailing us at: <b>humansofruralindia@gmail.com</b>
      </div>
      <div className="privacy-subheading">3. DISCLOSURE:</div>
      <div className="privacy-subheading-content">
        We may disclose your personal information if we are required by law to
        do so or if you violate our Terms of Service.
      </div>
      <div className="privacy-subheading">4. PAYMENT:</div>
      <div className="privacy-subheading-content"></div>
      <div className="privacy-subheading">5. COOKIES:</div>
      <div className="privacy-subheading-content">
        We use cookies to maintain the session of your user. It is not used to
        personally identify you on other websites.
      </div>
      <div className="privacy-subheading">6. AGE OF CONSENT:</div>
      <div className="privacy-subheading-content">
        By using this site, you represent that you are at least the age of
        majority in your state or province of residence, or that you are the age
        of majority in your state or province of residence and you have given us
        your consent to allow any of your minor dependents to use this site.
      </div>
      <div className="privacy-subheading">
        7. CHANGES TO THIS PRIVACY POLICY:
      </div>
      <div className="privacy-subheading-content">
        We reserve the right to modify this privacy policy at any time, so
        please review it frequently. Changes and clarifications will take effect
        immediately upon their posting on the website. If we make material
        changes to this policy, we will notify you here that it has been
        updated, so that you are aware of what information we collect, how we
        use it, and under what circumstances, if any, we use and/or disclose it.
        <br />
        If our store is acquired or merged with another company, your
        information may be transferred to the new owners so that we may continue
        to sell products to you.
      </div>
      <div className="privacy-subheading">
        8. QUESTIONS AND CONTACT INFORMATION:
      </div>
      <div className="privacy-subheading-content">
        If you would like to: access, correct, amend or delete any personal
        information we have about you, register a complaint, or simply want more
        information contact us by phone <b>9461852060</b> e-mail at
        <b>humansofruralindia@gmail.com</b>
      </div>
    </div>
  );
};

export default PrivacyAndPolicy;
