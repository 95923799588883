import React from "react";
import BlogDetail from "./blogDetails";
// import Header from "../components/Header";
// import Footer from "../components/Footer";

function BlogPage() {
  return (
    <div>
      {/* <Header /> */}
      <BlogDetail />
      {/* <Footer /> */}
    </div>
  );
}

export default BlogPage;
