import React, { useState } from "react";
import profilePhoto from "../../images/team/temp.png";
import "./Dashboard.scss";
import logo from "../../images/land-logo.png";
import emitralogo from "../../images/emitraLogo.jpg";
const filterConst = [
  "Filter",
  "ACTIVE",
  "INACTIVE",
  "1DAY",
  "3MONTHS",
  "6MONTHS",
];

const Dashboard = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    console.log(open);
    setOpen(!open);
  };
  const [filter, setFilter] = useState(filterConst[0]);

  return (
    <div>
      <nav className="navbar navbar-expand-sm fixed-top">
        <div className="navbar-content">
          <a className="navbar-brand">
            <img
              src={logo}
              alt="HK Logo"
              className="logo-img"
              style={{ width: "150px" }}
            />
            <img
              src={emitralogo}
              alt="HK Logo"
              className="logo-img"
              style={{ width: "50px" }}
            />
          </a>
          <div class="dropdown nav-item">
            <button
              type="button"
              class="btn dropdown-toggle border-0"
              data-bs-toggle="dropdown"
              onClick={handleOpen}
            >
              <i
                class="fa-regular fa-user border border-3 border-dark rounded-circle"
                // onClick={handleDropDown}
                // onClick={handleOpen}
              ></i>
            </button>
            {open && (
              <ul class="dropdown-menu dropdown-menu-center">
                <li>
                  <a class="dropdown-item" href="#">
                    Logout
                  </a>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>
      <div className="e-user-details">
        <div className="e-user-left">
          <img src={profilePhoto}></img>
          <button className="e-user-upload-img">Upload Image</button>
        </div>
        <div className="e-user-right">
          <div className="e-user">
            <div className="e-user-text">John</div>
            <span className="user-title"> Name </span>
          </div>
          <div className="e-user">
            <div className="e-user-text">john@gamil.com</div>
            <span className="user-title"> Email </span>
          </div>
          <div className="e-user">
            <div className="e-user-text">Dwarka Mor</div>
            <span className="user-title"> Address </span>
          </div>
          <div className="e-user">
            <div className="e-user-text">125417854123</div>
            <span className="user-title"> Kiosk ID </span>
          </div>
          <div className="e-user">
            <div className="e-user-text">74589521658741254136</div>
            <span className="user-title"> SSO ID </span>
          </div>
        </div>
      </div>
      <div className="e-user-dashboard">
        <div className="e-user-dashboard-first">
          <div className="">All Users</div>
          <div>
            <button className="e-user-upload-img">Add User</button>
          </div>
        </div>
        <div className="e-user-dashboard-second">
          <div className="">
            <select
              value={filter}
              className="filter"
              onChange={(e) => setFilter(e.target.value)}
            >
              {filterConst.map((each) => (
                <option value={each}>{each}</option>
              ))}
            </select>
          </div>
          <div className="e-user-search">
            <input type="text" placeholder="Find User..." />
            <i className="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>
        <div className="e-user-dashboard-third">
          <div className="e-user-table-headings">
            <div>User ID</div>
            <div>Name</div>
            <div>Phone No.</div>
            <div>E-mail</div>
            <div>Business Name</div>
            <div>Payment Status</div>
          </div>
          <div className="e-user-table-content">
            <div>#12546</div>
            <div>Somya</div>
            <div>7849651478</div>
            <div>somya@gmail.com</div>
            <div>
              <a href="shoe.digistall.in" target="_blank">
                shoe.digistall.in
              </a>
            </div>
            <div>
              <span className="e-user-status-paid">Paid</span>
            </div>
          </div>
          <div className="e-user-table-content">
            <div>#17846</div>
            <div>Mohit</div>
            <div>9863214587</div>
            <div>mohit@gmail.com</div>
            <div>
              <a href="shoe.digistall.in" target="_blank">
                jewellery.digistall.in
              </a>
            </div>
            <div>
              <span className="e-user-status-unpaid">Unpaid</span>
            </div>
          </div>
          <div className="e-user-table-content">
            <div>#41257</div>
            <div>Anand</div>
            <div>8523647815</div>
            <div>anand78@gmail.com</div>
            <div>
              <a href="shoe.digistall.in" target="_blank">
                retail.digistall.in
              </a>
            </div>
            <div>
              <span className="e-user-status-paid">Paid</span>
            </div>
          </div>
          <div className="e-user-table-content">
            <div>#84579</div>
            <div>Kritika</div>
            <div>6587489652</div>
            <div >kritika2412@gmail.com</div>
            <div>
              <a href="shoe.digistall.in" target="_blank">
                painting.digistall.in
              </a>
            </div>
            <div>
              <span className="e-user-status-unpaid">Unpaid</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
