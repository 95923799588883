import React, { useState } from "react";
import { useEffect } from "react";
const URL = "https://gotanbackend.digistall.in/api";
function MarketplaceCounter() {
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // console.log("fkj");
        const response = await fetch(`${URL}/counter/marketplaceCounter`);
        // console.log("fefef");

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        // console.log(data);
        setCounter(data);
      } catch (error) {
        console.error("Fetch Error:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="bg-gray-100 p-4 rounded-lg shadow-md">
      <h1 className="text-2xl font-bold text-center">Total Visits</h1>
      <div className="flex items-center justify-center mt-4">
        <h2 className="text-3xl font-bold text-blue-500">{counter}</h2>
      </div>
    </div>
  );
}

export default MarketplaceCounter;
