import React, { useState, useEffect } from "react";
import { auth } from "../../firebase";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setUserData, changeLoginStatus } from "../../fetures/user/userSlice";
import { verifyOTPNew } from "../../fetchCalls/fetchRoutes";

import "./phone.css";

function Fsign() {
  const { userLogged } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (userLogged) {
  //     navigate("/");
  //   }
  // });

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log(user.displayName, user.email);
      const email = user.email;
      //start
      try {
        const userData = { email };
        const { data } = await verifyOTPNew(userData);
        dispatch(setUserData(data));
        dispatch(changeLoginStatus());
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      if (error.code === "auth/cancelled-popup-request") {
        console.log("Popup was closed before completing the sign-in process.");
      } else {
        console.error(error);
      }
    }
  };

  return (
    <>
      {/* <div className="buttonsi"> */}
      <button onClick={handleGoogleSignIn} className="buttonsi">
        Continue with Google
      </button>
      {/* </div> */}
    </>
  );
}

export default Fsign;
