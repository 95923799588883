// // FAQComponent.js
// import React, { useState } from "react";
// import "./layout.css";
// import { FaPlus } from "react-icons/fa6";
// import { RxCross1 } from "react-icons/rx";

// const FAQComponent = ({ question, answer }) => {
//   const [showAnswer, setShowAnswer] = useState(false);

//   const toggleAnswer = () => {
//     setShowAnswer(!showAnswer);
//   };

//   return (
//     <div className="faq-container">
//       <div className="faq-question" onClick={toggleAnswer}>
//         {question}
//         <span className="faq-toggle">
//           {showAnswer ? (
//             <RxCross1 color="#64d5cf" />
//           ) : (
//             <FaPlus color="#64d5cf" />
//           )}
//         </span>
//       </div>
//       {showAnswer && <div className="faq-answer">{answer}</div>}
//     </div>
//   );
// };

// export default FAQComponent;
import React, { useState } from "react";
import "./layout.css";
import { FaPlus } from "react-icons/fa6";
import { RxCross1 } from "react-icons/rx";

const FAQComponent = ({ question, answer }) => {
  const [showAnswer, setShowAnswer] = useState(false);

  const toggleAnswer = () => {
    setShowAnswer(!showAnswer);
  };

  // Function to render text with clickable links
  const renderTextWithLinks = (text) => {
    const regex = /(https?:\/\/[^\s]+)/;
    return text.split(regex).map((part, index) => {
      if (part.match(regex)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        return part;
      }
    });
  };

  return (
    <div className="faq-container">
      <div className="faq-question font-bold" onClick={toggleAnswer}>
        {renderTextWithLinks(question)}
        <span className="faq-toggle">
          {showAnswer ? (
            <RxCross1 color="#64d5cf" />
          ) : (
            <FaPlus color="#64d5cf" />
          )}
        </span>
      </div>
      {showAnswer && (
        <div className="faq-answer">{renderTextWithLinks(answer)}</div>
      )}
    </div>
  );
};

export default FAQComponent;
