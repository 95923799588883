import React, { useState } from "react";
import { useEffect } from "react";
import { IoMdEye } from "react-icons/io";

const URL = "https://gotanbackend.digistall.in/api";
function EmitraCounter() {
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // console.log("fkj");
        const response = await fetch(`${URL}/counter/emitraCounter`);
        // console.log("fefef");

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data);
        setCounter(data);
      } catch (error) {
        console.error("Fetch Error:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="bg-white p-2">
      <h1 className="text-2xl font-bold text-center flex justify-center w-full m-auto">
        <IoMdEye size={40} className="text-black animate-pulse" />
      </h1>
      <div className="flex items-center justify-center mt-2">
        <h2 className="text-3xl font-bold text-black font-quicksand">
          {counter}
        </h2>
      </div>
    </div>
  );
}

export default EmitraCounter;
