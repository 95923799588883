import React from "react";
import EmitraGuide from "./emitraguide.pdf";
import "./LandingPage.scss";

function DownloadGuide() {
  const openPdfInNewTab = () => {
    window.open(EmitraGuide, "_blank");
  };

  return (
    <div>
      <button id="emitra-t-c-bttn" onClick={openPdfInNewTab}>
        Emitra Guide
      </button>
    </div>
  );
}

export default DownloadGuide;
