// import React, { useState } from "react";
// import axios from "axios";

// const AddBlog = () => {
//   const [passcode, setPasscode] = useState("");
//   const [isAuthorized, setIsAuthorized] = useState(false);
//   const [title, setTitle] = useState("");
//   const [description, setDescription] = useState("");
//   const [content, setContent] = useState([""]);
//   const [tags, setTags] = useState("");
//   const [coverImage, setCoverImage] = useState("");
//   const [message, setMessage] = useState("");

//   const handlePasscodeSubmit = (e) => {
//     e.preventDefault();
//     if (passcode === "shayaliza") {
//       setIsAuthorized(true);
//     } else {
//       alert("Incorrect passcode. Please try again.");
//     }
//   };

//   const handleAddContent = () => {
//     setContent([...content, ""]);
//   };

//   const handleContentChange = (index, value) => {
//     const updatedContent = content.map((item, i) =>
//       i === index ? value : item
//     );
//     setContent(updatedContent);
//   };

//   const handleRemoveContent = (index) => {
//     const updatedContent = content.filter((_, i) => i !== index);
//     setContent(updatedContent);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const newBlog = {
//         title,
//         description,
//         content,
//         tags: tags.split(",").map((tag) => tag.trim()),
//         coverImage,
//       };

//       const res = await axios.post(
//         "https://gotanbackend.digistall.in/api/blog/addblog",
//         newBlog
//       );
//       if (res.status === 201) {
//         // alert("Blog added successfully!");
//         setMessage("Blog added successfully!");
//         // Reset form
//         setTitle("");
//         setDescription("");
//         setContent([""]);
//         setTags("");
//         setCoverImage("");
//       }
//     } catch (error) {
//       console.error("Error adding blog:", error);
//       setMessage("Failed to add blog.");
//     }
//   };

//   return (
//     <div className="">
//       {!isAuthorized ? (
//         <div className="flex justify-center items-center h-screen bg-gray-100">
//           <form
//             onSubmit={handlePasscodeSubmit}
//             className="w-full max-w-md p-8 h-auto  bg-white shadow-lg rounded-lg"
//           >
//             <h2 className="text-2xl font-bold mb-6">Enter Passcode</h2>
//             <div className="mb-4">
//               <label
//                 className="block text-sm font-bold mb-2"
//                 htmlFor="passcode"
//               >
//                 Passcode
//               </label>
//               <input
//                 type="password"
//                 id="passcode"
//                 value={passcode}
//                 onChange={(e) => setPasscode(e.target.value)}
//                 className="w-full px-3 py-2 border rounded"
//                 required
//               />
//             </div>
//             <button
//               type="submit"
//               className="w-full bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
//             >
//               Submit
//             </button>
//           </form>
//         </div>
//       ) : (
//         // <form
//         //   onSubmit={handleSubmit}
//         //   className="w-full max-w-2xl p-8 bg-white shadow-lg rounded-lg"
//         // >
//         //   <h2 className="text-2xl font-bold mb-6">Add New Blog</h2>

//         //   <div className="mb-4">
//         //     <label className="block text-sm font-bold mb-2" htmlFor="title">
//         //       Title
//         //     </label>
//         //     <input
//         //       type="text"
//         //       id="title"
//         //       value={title}
//         //       onChange={(e) => setTitle(e.target.value)}
//         //       className="w-full px-3 py-2 border rounded"
//         //       required
//         //     />
//         //   </div>

//         //   <div className="mb-4">
//         //     <label
//         //       className="block text-sm font-bold mb-2"
//         //       htmlFor="description"
//         //     >
//         //       Description
//         //     </label>
//         //     <textarea
//         //       id="description"
//         //       value={description}
//         //       onChange={(e) => setDescription(e.target.value)}
//         //       className="w-full px-3 py-2 border rounded"
//         //       rows="3"
//         //       required
//         //     ></textarea>
//         //   </div>

//         //   <div className="mb-4">
//         //     <label className="block text-sm font-bold mb-2">Content</label>
//         //     {content.map((paragraph, index) => (
//         //       <div key={index} className="mb-2 flex">
//         //         <textarea
//         //           value={paragraph}
//         //           onChange={(e) => handleContentChange(index, e.target.value)}
//         //           className="w-full px-3 py-2 border rounded mr-2"
//         //           rows="3"
//         //           required
//         //         ></textarea>
//         //         {content.length > 1 && (
//         //           <button
//         //             type="button"
//         //             onClick={() => handleRemoveContent(index)}
//         //             className="bg-red-500 text-white px-3 py-2 rounded"
//         //           >
//         //             Remove
//         //           </button>
//         //         )}
//         //       </div>
//         //     ))}
//         //     <button
//         //       type="button"
//         //       onClick={handleAddContent}
//         //       className="bg-blue-500 text-white px-3 py-2 rounded"
//         //     >
//         //       Add Paragraph
//         //     </button>
//         //   </div>

//         //   <div className="mb-4">
//         //     <label className="block text-sm font-bold mb-2" htmlFor="tags">
//         //       Tags (comma separated)
//         //     </label>
//         //     <input
//         //       type="text"
//         //       id="tags"
//         //       value={tags}
//         //       onChange={(e) => setTags(e.target.value)}
//         //       className="w-full px-3 py-2 border rounded"
//         //       required
//         //     />
//         //   </div>

//         //   <div className="mb-4">
//         //     <label
//         //       className="block text-sm font-bold mb-2"
//         //       htmlFor="coverImage"
//         //     >
//         //       Cover Image URL
//         //     </label>
//         //     <input
//         //       type="text"
//         //       id="coverImage"
//         //       value={coverImage}
//         //       onChange={(e) => setCoverImage(e.target.value)}
//         //       className="w-full px-3 py-2 border rounded"
//         //       required
//         //     />
//         //   </div>

//         //   {message && (
//         //     <div className="mb-4 text-center text-green-500 font-bold">
//         //       {message}
//         //     </div>
//         //   )}

//         //   <button
//         //     type="submit"
//         //     className="w-full bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
//         //   >
//         //     Add Blog
//         //   </button>
//         // </form>
//         <form onSubmit={handleSubmit} className="w-11/12 mx-auto p-8 bg-white ">
//           <h2 className="text-3xl font-extrabold mb-6 text-gray-800">
//             Add New Blog
//           </h2>

//           <div className="mb-6">
//             <label
//               className="block text-sm font-medium text-gray-700 mb-2"
//               htmlFor="title"
//             >
//               Title
//             </label>
//             <input
//               type="text"
//               id="title"
//               value={title}
//               onChange={(e) => setTitle(e.target.value)}
//               className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//               required
//             />
//           </div>

//           <div className="mb-6">
//             <label
//               className="block text-sm font-medium text-gray-700 mb-2"
//               htmlFor="description"
//             >
//               Description
//             </label>
//             <textarea
//               id="description"
//               value={description}
//               onChange={(e) => setDescription(e.target.value)}
//               className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//               rows="3"
//               required
//             ></textarea>
//           </div>

//           <div className="mb-6">
//             <label className="block text-sm font-medium text-gray-700 mb-2">
//               Content
//             </label>
//             {content.map((paragraph, index) => (
//               <div key={index} className="mb-4 flex items-start">
//                 <textarea
//                   value={paragraph}
//                   onChange={(e) => handleContentChange(index, e.target.value)}
//                   className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent mr-3"
//                   rows="3"
//                   required
//                 ></textarea>
//                 {content.length > 1 && (
//                   <button
//                     type="button"
//                     onClick={() => handleRemoveContent(index)}
//                     className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700"
//                   >
//                     Remove
//                   </button>
//                 )}
//               </div>
//             ))}
//             <button
//               type="button"
//               onClick={handleAddContent}
//               className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300"
//             >
//               Add Paragraph
//             </button>
//           </div>

//           <div className="mb-6">
//             <label
//               className="block text-sm font-medium text-gray-700 mb-2"
//               htmlFor="tags"
//             >
//               Tags (comma separated)
//             </label>
//             <input
//               type="text"
//               id="tags"
//               value={tags}
//               onChange={(e) => setTags(e.target.value)}
//               className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//               required
//             />
//           </div>

//           <div className="mb-6">
//             <label
//               className="block text-sm font-medium text-gray-700 mb-2"
//               htmlFor="coverImage"
//             >
//               Cover Image URL
//             </label>
//             <input
//               type="text"
//               id="coverImage"
//               value={coverImage}
//               onChange={(e) => setCoverImage(e.target.value)}
//               className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//               required
//             />
//           </div>

//           {message && (
//             <div className="mb-6 text-center text-green-600 font-semibold">
//               {message}
//             </div>
//           )}

//           <button
//             type="submit"
//             className="w-full bg-green-600 text-white px-4 py-3 rounded-lg hover:bg-green-700 transition duration-300"
//           >
//             Add Blog
//           </button>
//         </form>
//       )}
//     </div>
//   );
// };

// export default AddBlog;

import React, { useState } from "react";
import axios from "axios";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const AddBlog = () => {
  const [passcode, setPasscode] = useState("");
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState([""]);
  const [tags, setTags] = useState("");
  const [coverImage, setCoverImage] = useState(null); // Change here to store the file
  const [message, setMessage] = useState("");
  const [uploading, setUploading] = useState(false);

  const handlePasscodeSubmit = (e) => {
    e.preventDefault();
    if (passcode === "shayaliza") {
      setIsAuthorized(true);
    } else {
      alert("Incorrect passcode. Please try again.");
    }
  };

  const handleAddContent = () => {
    setContent([...content, ""]);
  };

  const handleContentChange = (index, value) => {
    const updatedContent = content.map((item, i) =>
      i === index ? value : item
    );
    setContent(updatedContent);
  };

  const handleRemoveContent = (index) => {
    const updatedContent = content.filter((_, i) => i !== index);
    setContent(updatedContent);
  };

  const handleImageUpload = async () => {
    if (!coverImage) return "";
    setUploading(true);
    const storage = getStorage();
    const storageRef = ref(storage, `blogs/${Date.now()}-${coverImage.name}`);
    await uploadBytes(storageRef, coverImage);
    const url = await getDownloadURL(storageRef);
    setUploading(false);
    return url;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const coverImageUrl = await handleImageUpload();

      const newBlog = {
        title,
        description,
        content,
        tags: tags.split(",").map((tag) => tag.trim()),
        coverImage: coverImageUrl,
      };
      console.log(newBlog, "newBlog");
      const res = await axios.post(
        "https://gotanbackend.digistall.in/api/blog/addblog",
        newBlog
      );
      if (res.status === 201) {
        setMessage("Blog added successfully!");
        // Reset form
        setTitle("");
        setDescription("");
        setContent([""]);
        setTags("");
        setCoverImage(null);
      }
    } catch (error) {
      console.error("Error adding blog:", error);
      setMessage("Failed to add blog.");
    }
  };

  return (
    <div className="">
      {!isAuthorized ? (
        <div className="flex justify-center items-center h-screen bg-gray-100">
          <form
            onSubmit={handlePasscodeSubmit}
            className="w-full max-w-md p-8 h-auto bg-white shadow-lg rounded-lg"
          >
            <h2 className="text-2xl font-bold mb-6">Enter Passcode</h2>
            <div className="mb-4">
              <label
                className="block text-sm font-bold mb-2"
                htmlFor="passcode"
              >
                Passcode
              </label>
              <input
                type="password"
                id="passcode"
                value={passcode}
                onChange={(e) => setPasscode(e.target.value)}
                className="w-full px-3 py-2 border rounded"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            >
              Submit
            </button>
          </form>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="w-11/12 mx-auto p-8 bg-white ">
          <h2 className="text-3xl font-extrabold mb-6 text-gray-800">
            Add New Blog
          </h2>

          <div className="mb-6">
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="title"
            >
              Title
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              required
            />
          </div>

          <div className="mb-6">
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="description"
            >
              Description
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              rows="3"
              required
            ></textarea>
          </div>

          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Content
            </label>
            {content.map((paragraph, index) => (
              <div key={index} className="mb-4 flex items-start">
                <textarea
                  value={paragraph}
                  onChange={(e) => handleContentChange(index, e.target.value)}
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent mr-3"
                  rows="3"
                  required
                ></textarea>
                {content.length > 1 && (
                  <button
                    type="button"
                    onClick={() => handleRemoveContent(index)}
                    className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700"
                  >
                    Remove
                  </button>
                )}
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddContent}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300"
            >
              Add Paragraph
            </button>
          </div>

          <div className="mb-6">
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="tags"
            >
              Tags (comma separated)
            </label>
            <input
              type="text"
              id="tags"
              value={tags}
              onChange={(e) => setTags(e.target.value)}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              required
            />
          </div>

          <div className="mb-6">
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="coverImage"
            >
              Cover Image
            </label>
            <input
              type="file"
              id="coverImage"
              onChange={(e) => setCoverImage(e.target.files[0])}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              required
            />
          </div>

          {uploading && (
            <div className="mb-6 text-center text-blue-600 font-semibold">
              Uploading image, please wait...
            </div>
          )}

          {message && (
            <div className="mb-6 text-center text-green-600 font-semibold">
              {message}
            </div>
          )}

          <button
            type="submit"
            className="w-full bg-green-600 text-white px-4 py-3 rounded-lg hover:bg-green-700 transition duration-300"
            disabled={uploading}
          >
            Add Blog
          </button>
        </form>
      )}
    </div>
  );
};

export default AddBlog;
