import React from "react";
import "./layout.css";
import MarketPlaceCounter from "../utils/marketplaceCounter";

import { Link, useNavigate } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";

import { IoMail } from "react-icons/io5";
import c1 from "./images/c1.png";
import c2 from "./images/c2.png";
import c3 from "./images/c3.png";
import b1 from "./images/b1.png";
import b2 from "./images/b2.png";
import b3 from "./images/b3.png";
import b4 from "./images/b4.png";
import { IoIosCall } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import mylogo from "../../images/mylogo.png";
import { FaWhatsapp } from "react-icons/fa";
import { LuCheckCircle } from "react-icons/lu";
import FAQComponent from "./faqcomp";
import { FaPlug } from "react-icons/fa";
import Crousal from "./crousal";
import BCrousal from "./businessCrousal";
import LCrousal from "./lastCrousal";
import { PiPlugsBold } from "react-icons/pi";
import { PiPlugsConnectedBold } from "react-icons/pi";
import { CiLinkedin } from "react-icons/ci";

import Plug from "../../images/marketplace/plug.webp";
function Marketlayout() {
  const phoneNumber = "+919461852060";
  const emailtomail = "humansofruralindia@gmail.com";
  return (
    <>
      <div className="between">
        <img src={mylogo} className="mx-auto" />
      </div>
      <h1 className="between">Your Gateway to E-Commerce</h1>
      <div className="main-bg">
        <div className="market-layout md:flex md:flex-col md:items-center">
          {" "}
          <h1 className="head5 flex ">
            <PiPlugsConnectedBold className="plugic" />A Plug In and Plug Out
            franchise model
            {/* <FaPlug /> */}
            <PiPlugsBold className="plugic" />
          </h1>
          <p className="main-para">
            which enables digital entrepreneurs and team to build and manage
            marketplace easily.
          </p>
          {/* <div className="btn-container md:w-[200px]">
            <div className="btn2 ">
              <a href={`https://wa.me/${phoneNumber}`} className="chat-now-tag">
                <FaWhatsapp size={30} />
                <p>Chat Now</p>
              </a>
            </div>
          </div> */}
          {/* <div className="form md:w-[600px]">
            <div className="second-para">
              Please fill this form to get started
            </div>
            <input placeholder="Name*" className="input1" />
            <input placeholder="Email" className="input1" />
            <input placeholder="Phone" className="input1" />
            <input placeholder="City" className="input1" />
            <div className="continue-btn">Continue</div>
            <div className="continue-section">
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className="horizontal-line"></div> */}
      <div className="c-1">
        <h1 className="head2 md:text-center text-[19px] md:text-[23px] font-semibold">
          What is the Digistall Franchise Program ?
        </h1>
      </div>
      <div className="market-layout">
        <p className="para3">
          As a Digistall Franchise owner, you can operate a store on your store
          at your neighborhood. We offer services like{" "}
          <b>Online Rider booking</b> , <b>Tracking order</b>, etc . Our model
          enables businesses list their shops, it acts as a centralized hub for
          businesses to list their shops, making it convenient for customers to
          explore multiple stores from one place.
        </p>
      </div>
      <div className="c-2">
        <h1 className="head2 md:text-center text-[19px] md:text-[23px] font-semibold">
          How does the program work ?
        </h1>
      </div>
      <div className="market-layout">
        <div className="card-container md:flex  md:justify-around">
          <div className="market-card md:w-[420px]">
            <div className="big-card-img">
              <img src={c1} />
            </div>
            <div className="name">Store Setup</div>
            <div className="point">
              <LuCheckCircle className="tick" />
              Digistall will setup the store for you{" "}
            </div>
            <div className="point">
              <LuCheckCircle className="tick" />
              Sellers onboarded with Digistall will place their inventory at the
              store
            </div>
          </div>
          <div className="market-card md:w-[420px]">
            <div className="big-card-img">
              <img src={c2} />
            </div>
            <div className="name">Things included in Package</div>
            <div className="point">
              <LuCheckCircle className="tick" />
              Comprehensive package includes website templates, branding
              materials, Operational guidelines
            </div>
            <div className="point">
              <LuCheckCircle className="tick" />
              Additional services such as customising content, etc will also be
              provided.
            </div>
          </div>
          <div className="market-card md:w-[420px]">
            <div className="big-card-img">
              <img src={c3} />
            </div>
            <div className="name">Marketing and Promotion</div>
            <div className="point">
              <LuCheckCircle className="tick" />
              Get access to marketing materials and strategies to promote your
              platform locally.
            </div>
            <div className="point">
              <LuCheckCircle className="tick" />
              Guidance on digital marketing to upskill the business.
            </div>
          </div>
        </div>
      </div>
      <div className="c-1">
        <h1 className="head2 md:text-center text-[19px] md:text-[23px] font-semibold">
          Things we would take care of for your business
        </h1>
      </div>
      <div className="market-layout">
        <BCrousal />
      </div>
      <div className="c-2">
        <h1 className="head2 md:text-center text-[19px] md:text-[23px] font-semibold">
          {" "}
          How will you benefit from the program ?
        </h1>
      </div>
      <div className="market-layout">
        <div className="card-container md:flex md:justify-around">
          <div className="market-card md:w-[300px]">
            <div className="big-card-img">
              <img src={b1} />
            </div>
            <div className="name">Proven Business model</div>
            <div className="point">
              <LuCheckCircle className="tick" />
              Demonstrates consistent profitability and growth across different
              markets.
            </div>
            <div className="point">
              <LuCheckCircle className="tick" />
              Offers a replicable and scalable system for operations and
              management
            </div>
          </div>
          <div className="market-card md:w-[300px]">
            <div className="big-card-img">
              <img src={b2} />
            </div>
            <div className="name">Scalability</div>
            <div className="point">
              <LuCheckCircle className="tick" />
              Add listings and scale easily with digital tools.
            </div>
            <div className="point">
              <LuCheckCircle className="tick" />
              Grow smoothly using automated management systems
            </div>
          </div>
          <div className="market-card md:w-[300px]">
            <div className="big-card-img">
              <img src={b3} />
            </div>
            <div className="name">Training and support</div>
            <div className="point">
              <LuCheckCircle className="tick" />
              Offer comprehensive training modules for seamless business
              integration
            </div>
            <div className="point">
              <LuCheckCircle className="tick" />
              Provide 24/7 support to address operational challenges promptly
            </div>
          </div>
          <div className="market-card md:w-[300px]">
            <div className="big-card-img">
              <img src={b4} />
            </div>
            <div className="name">Brand Recognition</div>
            <div className="point">
              <LuCheckCircle className="tick" />
              As we all know Consistent branding builds trust and recognition
            </div>
            <div className="point">
              <LuCheckCircle className="tick" />
              Our Strong marketing boosts brand visibility and recall
            </div>
          </div>
        </div>
      </div>

      {/* <h1 className="head2">Franchise Testimonials</h1>
        <Crousal /> */}
      <div className="c-1">
        <h1 className="head2 md:text-center text-[19px] md:text-[23px] font-semibold">
          How will you make money from the programme
        </h1>
      </div>
      <div className="market-layout">
        <LCrousal />
      </div>

      {/* form start  */}
      {/* <div className="main-bg pt-[50px]">
        <div className="market-layout md:flex md:flex-col md:items-center">
          {" "}
          <div className="form md:w-[600px]">
            <div className="second-para">
              Please fill this form to get started
            </div>
            <input placeholder="Name*" className="input1" />
            <input placeholder="Email" className="input1" />
            <input placeholder="Phone" className="input1" />
            <input placeholder="City" className="input1" />
            <div className="continue-btn">Continue</div>
            <div className="continue-section"></div>
          </div>
        </div>
      </div> */}
      <div className="md:flex  mt-4 mb-5  ">
        <div class="relative m-auto hello  text-center  md:h-[250px] md:w-[500px] h-[250px] w-[373px] bg-gradient-to-r from-[#fffbf3] to-[#fff1d9] rounded-md shadow-lg border-2 border-black flex flex-col justify-center">
          <div class="text-black font-bold text-3xl mb-4 fontstyle3 ">
            Basic Subscription
          </div>

          <div className="flex justify-between ">
            <div>
              <div class="text-black font-bold text-xl flex ml-4 fontstyle2">
                <span className="text-5xl pr-3">50 </span> Stores
              </div>
              <div class="text-black"></div>
              <div class="text-black font-bold fontstyle3 text-base line-through flex ml-4">
                ₹ 15000 upfront Cost
              </div>

              <div class="text-black font-bold text-base flex ml-4 fontstyle3">
                ₹ 10000 upfront Cost
              </div>
            </div>
            {/* <div className="flex justify-end">jdf</div> */}
            <div class=" md:w-[270px] w-[160px]  from-[#313131] to-[#000000] bg-radial-pattern1"></div>
          </div>
          <div className="border9 mt-4 mb-3"></div>
          {/* <div className="border9 mt-4 "> */}
          <div className=" font-bold text-lg text-black fontstyle3 ">
            {/* <Link to={"admin.digistall.in"}> Get Started</Link> */}
            <a href="http://admin.digistall.in">Get Started</a>
          </div>
          {/* </div> */}

          <div class="absolute top-4 right-0 m-4 text-black fontstyle2 font-bold text-lg">
            35% Off
          </div>
        </div>
        <div class="relative m-auto hello2 text-center md:h-[250px] md:w-[500px] h-[250px] w-[373px] bg-gradient-to-r from-[#fffbf3] to-[#fff1d9] rounded-md shadow-lg border-2 border-black flex flex-col justify-center">
          <div class="text-black font-bold text-3xl mb-4 fontstyle3 ">
            Premium Subscription
          </div>

          <div className="flex justify-between ">
            <div>
              <div class="text-black font-bold text-xl flex ml-4 fontstyle2">
                <span className="text-5xl pr-3">100 </span> Stores
              </div>
              <div class="text-black"></div>
              <div class="text-black font-bold fontstyle3 text-base line-through flex ml-4">
                ₹ 25000 upfront Cost
              </div>

              <div class="text-black font-bold text-base flex ml-4 fontstyle3">
                ₹ 20000 upfront Cost
              </div>
            </div>
            {/* <div className="flex justify-end">jdf</div> */}
            <div class=" md:w-[270px] w-[160px]  from-[#313131] to-[#000000] bg-radial-pattern1"></div>
          </div>
          <div className="border9 mt-4 mb-3"></div>
          {/* <div className="border9 mt-4 "> */}
          <div className=" font-bold text-lg text-black fontstyle3">
            {/* <Link to={"admin.digistall.in"}> Get Started</Link> */}
            <a href="http://admin.digistall.in">Get Started</a>
          </div>
          {/* </div> */}

          <div class="absolute top-4 right-0 m-4 text-black fontstyle2 font-bold text-lg">
            45% Off
          </div>
        </div>
      </div>
      <div className="c-1">
        <h1 className="head2 md:text-center text-[19px] md:text-[23px] font-semibold">
          Frequently asked questions
        </h1>
      </div>
      <div className="faq-layout">
        <FAQComponent
          question={"What is Digistall?"}
          answer={
            "Digistall is a tool which enables sellers to manage their own online inventory with ease."
          }
        />
        <FAQComponent
          question={"What is Digistall Marketplace?"}
          answer={
            "Our Product enables you to start your own digital marketplace in your local area, where you can operate up to 100 storefronts digitally."
          }
        />
        <FAQComponent
          question={"When can I build my marketplace?"}
          answer={
            "Ah! That’s the question, This is a simple plug-in and playout model where can you start taking order in an hour."
          }
        />
        <FAQComponent
          question={"Do you provide logistics support?"}
          answer={
            "Yes we do, by our rider’s application. Onboarding Rider in your local area would be completely your responsibility."
          }
        />
        <FAQComponent
          question={"What is Rider and Parcel Service?"}
          answer={
            "Rider and parcel are two additional services in our portfolio, where you can manage riders and parcels in your area."
          }
        />
        <FAQComponent
          question={"Who will decide the Commission?"}
          answer={
            "Completely franchise owner! We will be providing you with a bundle of 100 stores in one Go."
          }
        />
        <FAQComponent
          question={"Where I can watch Demo?"}
          answer={
            "You can watch demo at https://Gotan.in and https://Jodhpur.app"
          }
        />
      </div>

      <div className="small-hr"></div>
      <MarketPlaceCounter />
      {/* footer  */}
      <div className="new-footer">
        <img src={mylogo} />
        <div className="callus">
          <div>
            {" "}
            <FaWhatsapp className="size" />
          </div>
          <div>
            {" "}
            <div className="action">HelpLine</div>
            <div>
              <a href={`https://wa.me/${phoneNumber}`} className="t-b">
                +91 9461852060
              </a>
            </div>
          </div>
        </div>
        <div className="callus">
          <div>
            {" "}
            <IoMail className="sm-ico size" />
          </div>
          <div className="mail">
            {" "}
            <div className="action">Mail Us</div>
            <div>
              <a href={`mailto:${emailtomail}`} className="t-b">
                humansofruralindia@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="new-foot">
        <Link
          to={"https://digistall.in/privacy_and_policy"}
          className="border-2 border-black rounded-lg p-2"
        >
          {" "}
          Privacy Policy
        </Link>
        <Link
          to={"https://digistall.in/terms_and_conditions"}
          className="border-2 border-black rounded-lg p-2"
        >
          Terms and Condition
        </Link>
        <Link
          to={"https://digistall.substack.com/"}
          className="border-2 border-black rounded-lg p-2"
        >
          Newsletter
        </Link>
      </div>
      <div className="hr"></div>

      <div className="last-foot">
        <div className="gap">
          Follow{" "}
          <Link to={"https://www.facebook.com/humansofruralindia/"}>
            {" "}
            <FaFacebook className="size2" />
          </Link>
          <Link
            to={
              "https://www.linkedin.com/company/humans-of-rural-india/mycompany/"
            }
          >
            <CiLinkedin className="size2" />
          </Link>
          <Link to={"https://www.instagram.com/digistallapp/"}>
            <FaInstagram className="size2" />
          </Link>
        </div>
        <div>Siyag Rural Market Private Limited</div>
        <div>CIN No. :- U52100RJ2021PTC077111</div>
        <div>GSTIN :- 08ABGCS8909L1ZN</div>
        <div className="copyright">
          Copyright © Digistall | 2024 . All rights reserved.
        </div>
      </div>
    </>
  );
}

export default Marketlayout;
