import { useState } from "react";
import ImageNotFound from "./../../images/picture-not-available.jpg";
const formatter = new Intl.NumberFormat(undefined, {
  currency: "INR",
  style: "currency",
});

const OrderItem = ({ orderData }) => {
  const {
    orderId,
    productImage,
    productName,
    shopId,
    totalCartValue,
    productCount,
    orderStatus,
    address,
    date,
    orderNote,
  } = orderData;

  const expectedDeliveryDates = new Date().toDateString().split(" ");
  const [notes, setNotes] = useState(false);
  let status = "";
  let statusColor = "";
  switch (orderStatus) {
    case "PENDING":
      status = `Your order is Pending`;
      statusColor = "#3a2cff";
      break;
    case "CONFIRMED":
      status = "Your Order was Confirmed";
      statusColor = "#ff9500";
      break;
    case "SHIPPED":
      status = "Your Order is Shipped";

      // status =
      //   !orderNote &&
      //   `Expected Delivery date  ${expectedDeliveryDates[2]} ${expectedDeliveryDates[1]} ${expectedDeliveryDates[3]}`;
      statusColor = "#00ff21";
      break;
    case "CANCEL":
      status = `Your order was Canceled`;
      statusColor = "#ff0000";
      break;
    default:
      break;
  }

  return (
    <div className="flex gap-2 mb-3 py-2 w-11/12 mx-auto bg-slate-100 border-2 border-slate-300 rounded-lg ">
      <div className="self-center px-2 w-1/3">
        {/* Product Image */}
        {!productImage ? (
          <img
            src={ImageNotFound}
            alt="order-item"
            className="w-24 h-24 rounded-md "
          />
        ) : (
          <img
            src={productImage}
            alt="order-item"
            className="w-24 h-24 rounded-md"
          />
        )}
      </div>
      <div className="w-2/3">
        {/* Product Details */}
        <div>
          {productName.length > 22
            ? productName.slice(0, 22) + ".."
            : productName}
        </div>
        <div>Sold by: {shopId}</div>
        <div>
          Amount:{" "}
          <span style={{ color: "#28a745" }}>
            {formatter.format(Number(totalCartValue) + 20)}
          </span>
        </div>
        <div style={{ color: statusColor }}>{status}</div>
        {notes && (
          <div>
            <div style={{ color: statusColor }}>
              {`Delivery Partner: ${orderNote.deliveryPartner}`}
              <br />
              {`ShippingID: ${orderNote.ShippingId}`}
              <br />
              {`Expected Delivery Date & Time: ${orderNote.deliveryDate} - ${orderNote.deliveryTime}`}
            </div>
          </div>
        )}
        {orderNote && (
          <div className="w-full">
            {/* <div style={{ color: statusColor }}>Your order is Shipped</div> */}
            <div
              className=" justify-end w-full flex pr-3 cursor-pointer"
              onClick={() => setNotes(!notes)}
            >
              {!notes ? "View Details" : "Hide Details"}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderItem;
