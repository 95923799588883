import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../firebase";
import { FaSpinner } from "react-icons/fa";
import { useState } from "react";
import DigistallLogo from "./../images/image 4digistall_logo.png";
import { BsGoogle } from "react-icons/bs";

const CustomerLogin = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const subdomain = queryParams.get("subdomain");
  const [loading, setLoading] = useState(false);

  const handleGoBack2 = () => {
    window.location.href = `https://${subdomain}.digistall.in/customerLogin`;
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setLoading(true); // Start loading

      const user = result.user;
      const email = user.email;

      if (email) {
        try {
          Cookies.set("storeLoginData", JSON.stringify(email), {
            domain: ".digistall.in",
          });
          Cookies.set("storeLoginStatus", true, { domain: ".digistall.in" });
          handleGoBack2();
        } catch (error) {
          console.error("Error verifying OTP:", error);
        }
      } else {
        console.error("No email found for user");
      }
    } catch (error) {
      if (error.code === "auth/cancelled-popup-request") {
        console.log("Popup was closed before completing the sign-in process.");
      } else {
        console.error("Firebase authentication error:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <FaSpinner className="animate-spin text-blue-500 text-5xl" />
      ) : (
        <div className="md:w-9/12 w-11/12 m-auto h-screen overflow-hidden px-4">
          <div className="flex flex-row gap-3 pt-4">
            <Link to={"https://digistall.in/"}>
              <img
                src={DigistallLogo}
                className="w-[40px] h-[40px]"
                alt="Digistall Logo"
              />
            </Link>
            <div className="py-2 merriweather-bold">Digistall </div>
          </div>
          <div className="flex flex-col items-center justify-center h-screen text-center -mt-12">
            {/* <h1 className="text-3xl font-bold mb-4">One Step Login/Signup</h1> */}
            <button
              onClick={handleGoogleSignIn}
              className="bg-[#0293A8] text-white font-semibold py-4 px-6 rounded-lg shadow-md"
            >
              Continue with Google
              <BsGoogle className="ml-4" />
            </button>
            <p className="mt-6 text-lg text-gray-700">
              Buy the products with our Digistall platform!
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerLogin;
